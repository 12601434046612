import { memo } from 'react';
import CircularProgress from '@mui/material/CircularProgress';

/**
 * The FuseSplashScreen component is responsible for rendering a splash screen with a logo and a loading spinner.
 * It uses various MUI components to render the logo and spinner.
 * The component is memoized to prevent unnecessary re-renders.
 */
function FuseSplashScreen() {
	return (
		<div id="fuse-splash-screen">
			<div className="flex flex-1 h-full w-full self-center flex-col items-center justify-center p-24">
				<img
					width="128"
					src="bosanet-new-black.png"
					alt="logo"
				/>
			</div>
			<div className="w-full flex justify-center my-12">
				<CircularProgress size={50} style={{ color: '#4575D5' }} />
			</div>
		</div>
	);
}

export default memo(FuseSplashScreen);
