import React, { useState } from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import CustomTextField from '../CustomTextField';

/**
 * CustomAutocomplete is a React component that encapsulates the Autocomplete component.
 * component of Material-UI with custom functionality.
 *
 * @param {Object} props - Properties of the component.
 * @param {string} props.label - Input field label.
 * @param {any} props.value - Current selected value.
 * @param {Array} props.options - Options for the Autocomplete component.
 * @param {function} props.onChange - Function called when selection changes.
 * @param {boolean} props.error - Indicates if there is an error in the input.
 * @param {Object} props.styles - Additional styles for the Autocomplete component.
 */

function CustomAutocomplete(props) {
  const { label, value, options, onChange, error, styles, helperText, required } = props;
  const [autocompleteValue, setAutocompleteValue] = useState(value || null);

  /**
   * Handles the change in the Autocomplete component.
   * @param {Object} event - Change event object.
   * @param {any} newValue - New value selected.
   */
  const handleAutocompleteChange = (event, newValue) => {
    setAutocompleteValue(newValue);
    onChange(newValue);
  };

  return (
    <Autocomplete
     {...props}
      sx={{ ...styles }}
      value={autocompleteValue}
      onChange={handleAutocompleteChange}
      options={options}
      getOptionLabel={(option) =>
        typeof option === 'object' ? option.label : String(option)
      }
      isOptionEqualToValue={(option, value) => option.value === value.value}
      renderInput={(params) => (
        <CustomTextField
          {...params}
          label={label}
          variant="outlined"
          error={error}
          helperText={helperText}
          required={required}
        />
      )}
    />
  );
}

export default CustomAutocomplete;
