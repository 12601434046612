import React from 'react';
import { useTranslation } from 'react-i18next';

import { Dialog, IconButton, Slide, } from '@mui/material';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import ChevronRightRoundedIcon from '@mui/icons-material/ChevronRightRounded';

import CustomButton from '@components/CustomButton';
import CustomLoading from '@components/CustomLoading';

/**
 * Transition is a function that creates a transition component using React's forwardRef function.
 *
 * @param {object} props - The properties of the Transition component.
 * @param {React.Ref} ref - Ref that is passed to the Slide component.
 * @returns {React.Component} - Transition component using Slide.
 */
const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

function CustomDialog(props) {

    const { maxWidth, open, close, fullScreen, handleAction, loadingInfo } = props;
    const { t } = useTranslation();

    return (
        <Dialog
            disableEscapeKeyDown
            TransitionComponent={Transition}
            PaperProps={{
                sx: {
                    maxWidth: `${maxWidth} !important`,
                },
            }}
            open={open}
            fullScreen={fullScreen}
            // onClose={close}
            className="rounded-24"
            style={{
                boxShadow: "0px 2px 16px 4px rgba(38, 41, 64, 0.1)",
            }}
        >
            <div className="p-1">
                <div className="flex justify-end">
                    <IconButton onClick={close}>
                        <CloseRoundedIcon className="text-48" size={24} color="action" />
                    </IconButton>
                </div>
                <div className="flex flex-col items-center px-5 pb-5 md:px-4 md:pb-10">
                    <div
                        className="rounded-full w-fit mb-5"
                    >
                        <img
                            width="128"
                            src="bosanet-new-black.png"
                            alt="logo"
                        />
                    </div>
                    <div
                        className="text-14 font-medium  mb-8 mr-0 p-5  md:mb-8 md:mr-5 md:p-8"
                        color="#000"
                        style={{  boxShadow: '0px 4px 10px #ccc' }}
                    >
                        <div className='mb-4'>
                            {t('completed_questions')}
                        </div>
                        <div className="click-button text-center">
                            {loadingInfo ? (
                                <CustomLoading />
                            ) : (
                                <CustomButton
                                    typebutton={2}  
                                    label={t('generate')}
                                    className="primary"
                                    height="medium"
                                    onClick={handleAction}
                                    endIcon={<ChevronRightRoundedIcon fontSize="large" />}
                                />
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </Dialog>
    );
};

export default CustomDialog;
