import { useState } from 'react';
import {
  Paper,
  useMediaQuery 
} from '@mui/material';

import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const Accordion = ({ index, question, answerDescription, answerValue, answerComplement }) => {
  const [isOpen, setIsOpen] = useState(false);
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'));

  const toggleAccordion = () => {
    setIsOpen(!isOpen);
  };

  // Función para asignar color basado en el answer_value
  const getAnswerColor = (value) => {
    switch (value) {
      case 1:
        return 'text-[red]';
      case 3:
        return 'text-[#6c757d]';
      default:
        return 'text-[#00c851]';
    }
  };

  return (
    <Paper className="w-full mb-[0.1rem]" key={index} style={{ borderBottomRightRadius: 0, borderBottomLeftRadius: 0 }}>
      {/* Encabezado del Acordeón */}
      <div
        className="flex items-center p-3 cursor-pointer justify-between"
        onClick={toggleAccordion}
        aria-controls={`panel${index}-content`}
        id={`panel${index}-header`}
      >
        {/* Pregunta */}
        <div className='flex'>
          <p className="font-bold md:text-[16px] text-[14px]">{question}</p>
          {/* Descripción de la respuesta y el ícono */}
          <div className="flex items-center ml-2 md:text-[16px] text-[14px]">
            <CheckCircleRoundedIcon
              style={{ marginLeft: 10 }}
              className={`ml-2 ${getAnswerColor(answerValue)}`}
            />
            &nbsp;({answerDescription})
          </div>
        </div>
        {/* Indicador de apertura/cierre con icono ExpandMore */}
        <span className={`ml-2 transform transition-transform ${isOpen ? 'rotate-180' : ''}`}>
          <ExpandMoreIcon />
        </span>
      </div>

      {/* Detalles del Acordeón (Contenido Expandible) */}
      {isOpen && (
        <div className="p-4 mt-1 bg-white">
          <div
            className="text-sm md:text-base content-preview break-words"
            style={{ maxWidth: isMobile ? '320px' :'100%'  }}
            dangerouslySetInnerHTML={{ __html: answerComplement }}
          />
        </div>
      )}
    </Paper>
  );
};

export default Accordion;
