import React, { useState, useEffect } from 'react';
import { isEmpty } from 'ramda';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { useForm, Controller, FormProvider } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import {
  CircularProgress,
  useMediaQuery,
  IconButton,
  InputAdornment
} from '@mui/material';
import { es } from 'date-fns/locale';

import AccountCircleRoundedIcon from '@mui/icons-material/AccountCircleRounded';
import VisibilityRoundedIcon from '@mui/icons-material/VisibilityRounded';
import VisibilityOffRoundedIcon from '@mui/icons-material/VisibilityOffRounded';
import LocalGroceryStoreRoundedIcon from '@mui/icons-material/LocalGroceryStoreRounded';

import CustomLoading from '@components/CustomLoading';
import CustomButton from '@components/CustomButton';
import CustomTextField from '@components/CustomTextField';

import { getProfileById, updateProfile } from '../../../store/personSlice';
import { showMessage } from '../../../store/messageSlice';

function Index() {
  const dispatch = useDispatch();
  const authUser = useSelector(({ user }) => user.user);
  const smallScreen = useMediaQuery('(min-width:600px)');
  const { t } = useTranslation();

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (!isEmpty(authUser)) {
      setLoading(false);
      handleOpen();
      setLoading(false);
    }
  }, [authUser, dispatch]);

  const handleOpen = async () => {
    const url = `https://bosanet.totalcode-oc.com/suppliers/logged_in_token/?supplier_email=${authUser?.user_email}&supplier_token=${authUser?.user_access_web}`;
    window.open(url, '_blank');
  }

  return (
    <div>
      <div className="w-full flex justify-between items-center mt-4 bg-white p-4 rounded-lg h-16">
        <p className="text-xl font-semibold">{t('my_products')}</p>
      </div>
      <div className="w-full mt-7 bg-white px-2 py-6 rounded-lg flex flex-col">
        {loading ? (
          <CustomLoading />
        ) : (
          <div className='text-center text-[18px]'>
            <CustomButton
              label={t('go_marketplace')}
              typebutton={2}
              icon={<LocalGroceryStoreRoundedIcon fontSize="large" />}
              onClick={handleOpen}
            />
          </div>
        )}
      </div>
    </div>
  );
}

export default Index;
