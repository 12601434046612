import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  Slide,
} from '@mui/material';
import { makeStyles } from '@mui/styles';

import { useForm, Controller, FormProvider } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { es } from 'date-fns/locale';
import { format, parseISO, parse, isValid } from 'date-fns';

import TodayRoundedIcon from '@mui/icons-material/TodayRounded';

import CustomButton from '@components/CustomButton';
import CustomTextField from '@components/CustomTextField';
import CustomAutocomplete from '@components/CustomAutocomplete';
import CustomDatePicker from '@components/CustomDatePicker';

import {
  create,
  update,
} from '../../../store/sellerServicesSlice';
import {
  getServices
} from '../../../store/serviceSlice';
import {
  getSellers
} from '../../../store/sellerSlice';
import {
  getCountries
} from '../../../store/stateSlice';
import { showMessage } from '../../../store/messageSlice';

/**
 * Transition is a function that creates a transition component using React's forwardRef function.
 *
 * @param {object} props - The properties of the Transition component.
 * @param {React.Ref} ref - Ref that is passed to the Slide component.
 * @returns {React.Component} - Transition component using Slide.
 */
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles((theme) => ({
  popper: {
    '& .MuiPopper-root': { zIndex: 9999 },
  },
}));

const defaultValues = {
  sese_service_id: null,
  sese_seller_id: null,
  sese_payment_value: '',
  sese_payment_date: null,
  sese_sku: '',
};

const schema = yup.object().shape({
  sese_sku: yup.string().required('SKU es requerido'),
  sese_payment_value: yup
    .string()
    .required('Valor es requerido')
    .test('is-decimal', 'El número debe ser válido', value => {
      if (!value) return false;
      // Reemplaza el punto por nada y la coma por un punto
      const normalizedValue = value.replace(/\./g, '').replace(/,/g, '');
      return /^\d+(\.\d+)?$/.test(normalizedValue);
    })
    .transform(value => {
      // Reemplazar el formato de coma para manejarlo como número decimal
      return value ? value.replace(/\./g, '.').replace(/,/g, ',') : value;
    }),
  sese_payment_date: yup
    .string()
    .test('is-valid-date', 'Fecha inválida', (value) => {
      if (!value) return false;

      // Convierte el valor a un objeto Date
      const date = new Date(value);

      // Verifica si la fecha es válida
      if (!isValid(date)) return false;

      // Verifica que el año sea mayor o igual a 1900
      const year = date.getFullYear();
      return year >= 1900 && year <= 2050;;
    })
    .required('Fecha es requerida'),
  sese_service_id: yup.object().required('Servicio es requerida'),
  sese_seller_id: yup.object().required('Seller es requerida'),
});

/**
 * Props for the CustomDialog component.
 * @property {boolean} open - Determines whether the dialog is open or closed.
 * @property {Function} handleClose - Callback function to handle the closing of the dialog.
 * @property {Function} handleRefresh - Callback function to handle the closing of the dialog.
 * @property {string} type - The type of operation ('create' or 'edit') the dialog is performing.
 * @property {Object} item - Object containing information about the partner being edited.
 */
function CustomDialog(props) {
  const { open, handleClose, type, item, handleRefresh } = props;
  const dispatch = useDispatch();
  const classes = useStyles();

  const services = useSelector(({ service }) => service.servicesList);
  const sellers = useSelector(({ seller }) => seller.sellersList);

  const [loading, setLoading] = useState(false);

  const methods = useForm({
    mode: 'onChange',
    defaultValues,
    resolver: yupResolver(schema),
  });
  const { reset, control, formState, handleSubmit, setValue } = methods;
  const { errors } = formState;

  const initDialog = useCallback(async () => {
    reset({
      ...item,
      sese_service_id: {
        label: `(${item.fk_service?.service_hubspot_sku}) ${item.fk_service?.service_name}`,
        value: item.sese_service_id,
      },
      sese_seller_id: {
        label: `${item.fk_seller?.seller_name}`,
        value: item.sese_seller_id,
      },
      sese_payment_date: parseISO(item?.sese_payment_date),
    });
  });

  useEffect(() => {
    const fetch = async () => {
      if (type === 'edit') {
        initDialog();
      }
    };
    fetch();
  }, [type, reset, item]);

  useEffect(() => {
    async function init() {
      await dispatch(getCountries());
      await dispatch(getSellers());
      await dispatch(getServices());
    }
    init();
  }, []);

  /**
  * Function to clean the information
  */
  const cleanData = () => {
    reset(defaultValues);
  };

  // --------------------------------------------
  const optionServices = services
    ? services.map((e) => ({
      value: e.service_id,
      label: `(${e.service_hubspot_sku}) ${e.service_name}`,
    }))
    : [];

  const optionSellers = sellers
    ? sellers.map((e) => ({
      value: e.seller_id,
      label: e.seller_name,
    }))
    : [];

  // const optionCountries = countries
  //   ? countries.map((e) => ({
  //     value: e.country_id,
  //     label: e.country_name,
  //   }))
  //   : [];
  // --------------------------------------------

  /**
   * Function to send the new partner information.
   * Checks if the 'PartnersName' field is empty and displays an error if so, otherwise closes the modal.
   * @returns {void} - Returns nothing.
   */
  const handleAction = async (data) => {
    setLoading(true);
    if (type === 'create') {
      const response = await dispatch(create({
        ...data,
        sese_seller_id: data.sese_seller_id.value,
        sese_service_id: data.sese_service_id.value,
        sese_payment_date: format(new Date(data.sese_payment_date), 'yyyy-MM-dd'),
      }));
      if (response) {
        await dispatch(showMessage({ message: 'Seller Service creada', variant: 'success' }));
      }
    } else {
      const response = await dispatch(update({
        ...data,
        sese_seller_id: data.sese_seller_id.value,
        sese_service_id: data.sese_service_id.value,
        sese_payment_date: format(new Date(data.sese_payment_date), 'yyyy-MM-dd'),
      },
        item.sese_id
      ));
      if (response) {
        await dispatch(showMessage({ message: 'Seller Service actualizada', variant: 'success' }));
      }
    }
    cleanData();
    setLoading(false);
    handleClose();
    handleRefresh();

  };

  return (
    <Dialog open={open} TransitionComponent={Transition} /*onClose={close}*/>
      <DialogTitle className="bg-gray-100 flex items-center justify-center rounded-t-lg">
        <p className="font-bold text-xl">
          {type === 'create' ? 'Crear' : 'Editar'} Seller Service
        </p>
      </DialogTitle>
      <DialogContent sx={{ width: '500px' }}>
        <FormProvider {...methods}>
          <div className="mt-4 flex flex-col">
            <Controller
              name="sese_seller_id"
              control={control}
              render={({ field: { onChange, value, onBlur, ref } }) => (
                <CustomAutocomplete
                  styles={{ mb: 2 }}
                  label="Seller"
                  id="sese_seller_id"
                  value={value}
                  options={optionSellers}
                  onChange={(event, newValue) => {
                    onChange(event);
                  }}
                  renderOption={(props, option) => (
                    <li {...props} key={option.key}>
                      {option.label}
                    </li>
                  )}
                  error={!!errors.sese_seller_id}
                  helperText={errors?.sese_seller_id?.message}
                  required
                />
              )}
            />
            <Controller
              name="sese_service_id"
              control={control}
              render={({ field: { onChange, value, onBlur, ref } }) => (
                <CustomAutocomplete
                  styles={{ mb: 2 }}
                  label="Servicio"
                  id="sese_service_id"
                  value={value}
                  options={optionServices}
                  // noOptionsText={'Sin resultados'}
                  onChange={(event, newValue) => {
                    onChange(event);
                  }}
                  renderOption={(props, option) => (
                    <li {...props} key={option.key}>
                      {option.label}
                    </li>
                  )}
                  error={!!errors.sese_service_id}
                  helperText={errors?.sese_service_id?.message}
                  required
                />
              )}
            />
            {/* <Controller
              name="sese_payment_date"
              control={control}
              render={({ field }) => (
                <CustomDatePicker
                  maxDate={new Date()}
                  id="sese_payment_date"
                  label="Fecha"
                />
              )}
            /> */}
            <Controller
              control={control}
              name="sese_payment_date"
              render={({ field }) => (
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DatePicker
                    {...field}
                    clearable
                    value={field.value}
                    inputFormat="dd-MM-yyyy"
                    className={classes.popper}
                    disableFuture
                    openTo="month"
                    // disablePast
                    label="Fecha pago"
                    // onChange={(event, newValue) => {
                    //   field.onChange(event);
                    // }}
                    renderInput={(_props) => (
                      <CustomTextField
                        {..._props}
                        defaultValue=""
                        styles={{ mt: 1, mb: 2, width: '100%' }}
                        id="sese_payment_date"
                        error={!!errors.sese_payment_date}
                        helperText={errors?.sese_payment_date?.message}
                        required
                      // {...field}
                      />
                    )}
                    components={{
                      OpenPickerIcon: () => (
                        <TodayRoundedIcon className="text-48 mr-8" size={14} />
                      ),
                    }}
                  />
                </LocalizationProvider>
              )}
            />
            <Controller
              name="sese_payment_value"
              control={control}
              render={({ field }) => (
                <CustomTextField
                  {...field}
                  styles={{ mt: 1, mb: 2, width: '100%' }}
                  id="sese_payment_value"
                  label="Valor"
                  // value={form.cli_documento}
                  error={!!errors.sese_payment_value}
                  helperText={errors?.sese_payment_value?.message}
                  required
                />
              )}
            />
            <Controller
              name="sese_sku"
              control={control}
              render={({ field }) => (
                <CustomTextField
                  {...field}
                  styles={{ mt: 1, mb: 2, width: '100%' }}
                  id="sese_sku"
                  label="Hubspot SKU"
                  // value={form.cli_documento}
                  error={!!errors.sese_sku}
                  helperText={errors?.sese_sku?.message}
                  required
                />
              )}
            />
          </div>
          <div className="flex justify-end my-4 items-center">
            <p
              className="mx-4 underline text-base text-gray-600 cursor-pointer"
              onClick={() => {
                handleClose();
                setLoading(false);
                cleanData();
              }}
            >
              Cancel
            </p>
            {loading ? (
              <CircularProgress
                style={{ color: '#4575D5', marginRight: 4 }}
                size={24}
              />
            ) : (
              <CustomButton
                label="Guardar"
                typebutton={2}
                onClick={handleSubmit(handleAction)}
              />
            )}
          </div>
        </FormProvider>
      </DialogContent>
    </Dialog>
  );
}

export default CustomDialog;
