import axios from 'axios';
import { createSlice } from '@reduxjs/toolkit';
import API from '../configs/urlsConfig';
import { showMessage } from './messageSlice';

export const getStateId = (id) => async (dispatch) => {
  try {
    const URL = `${API.baseUrl}/${API.endpoints.states}/lista/${id}`;
    const result = await axios.get(URL);
    await dispatch(setStatesList(result.data));
    return true;
  } catch (error) {
    throw new Error(`*** REDUX -> getStateId *** ${error}`);
  }
};

export const getAll = (page, per_page, orderBy, order, params) => async (dispatch) => {
  try {
    const queryString = `?page=${page}&rows=${per_page}&order_field=${orderBy}&order_type=${order}&filters=${JSON.stringify(params)}`;
    const URL = `${API.baseUrl}/${API.endpoints.states}`;
    const result = await axios.get(URL + queryString);
    await dispatch(setDataList(result.data));
    return result;
  } catch (error) {
    throw new Error(`*** REDUX -> getAll *** ${error}`);
  }
};

export const create = (data) => async (dispatch) => {
  try {
    const URL = `${API.baseUrl}/${API.endpoints.states}`;
    await axios.post(URL, data);
    return true;
  } catch (error) {
    throw new Error(`*** REDUX -> create *** ${error}`);
  }
};

export const update = (data, id) => async (dispatch) => {
  try {
    const URL = `${API.baseUrl}/${API.endpoints.states}/${id}`;
    await axios.put(URL, data);
    return true;
  } catch (error) {
    throw new Error(`*** REDUX -> update *** ${error}`);
  }
};

export const getCountries = () => async (dispatch) => {
  try {
    const URL = `${API.baseUrl}/${API.endpoints.countries}/lista`;
    const result = await axios.get(URL);
    await dispatch(setCountriesList(result.data));
    return true;
  } catch (error) {
    throw new Error(`*** REDUX -> getCountries *** ${error}`);
  }
};

const stateSlice = createSlice({
  name: 'state',
  initialState: {
    enabledIntegration: false,
    dataList: [],
    statesList: [],
    countriesList: [],
    page: 0,
    rowsPerPage: 10,
    total: 0,
  },
  reducers: {
    setCleanState: (state) => {
      state.statesList = [];
    },
    setDataList: (state, action) => {
      state.dataList = action.payload.rows;
      state.total = action.payload.count;
    },
    setStatesList: (state, action) => {
      state.statesList = action.payload;
    },
    setCountriesList: (state, action) => {
      state.countriesList = action.payload;
    },
    setRowsPerPage: (state, action) => {
      state.rowsPerPage = action.payload;
    },
    setPage: (state, action) => {
      state.page = action.payload;
    },
  }
});

export const { setCleanState, setDataList, setStatesList, setCountriesList, setRowsPerPage, setPage, setTotalRows } =
stateSlice.actions;

export default stateSlice.reducer;
