import React, { useState, useEffect } from 'react';
import { isEmpty } from 'ramda';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { useForm, Controller, FormProvider } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import {
  CircularProgress,
  useMediaQuery,
  IconButton,
  InputAdornment
} from '@mui/material';
import { es } from 'date-fns/locale';

import AccountCircleRoundedIcon from '@mui/icons-material/AccountCircleRounded';
import VisibilityRoundedIcon from '@mui/icons-material/VisibilityRounded';
import VisibilityOffRoundedIcon from '@mui/icons-material/VisibilityOffRounded';

import CustomLoading from '@components/CustomLoading';
import CustomButton from '@components/CustomButton';
import CustomTextField from '@components/CustomTextField';

import { getProfileById, updateProfile } from '../../../store/supplierSlice';
import { showMessage } from '../../../store/messageSlice';

const defaultValues = {
  supplier_company: '',
  supplier_name: '',
  supplier_lastname: '',
  supplier_email: '',
  supplier_phone: '',
  supplier_address: '',

  current_password: '',
  new_password: '',
  new_password_confirm: '',
};

const schema = yup.object().shape({
  supplier_company: yup.string().required('Empresa es requerido'),
  supplier_name: yup.string().required('Nombres es requerido'),
  supplier_lastname: yup.string().required('Apellidos es requerido'),
  supplier_email: yup.string().email('Email no válido').required('Email es requerido'),
  supplier_phone: yup
    .string()
    .notRequired() // No requerido por defecto
    .when('supplier_phone', {
      is: (value) => value && value.length > 0, // Si está lleno, aplica validaciones
      then: (schema) =>
        schema
          .matches(/^[0-9]+$/, 'Celular debe contener solo números')
          .min(12, 'Celular debe tener al menos 12 dígitos incluyendo el indicativo del país')
          .max(15, 'Celular debe tener un máximo de 15 dígitos incluyendo el indicativo del país'),
      otherwise: (schema) => schema, // No hace nada si está vacío
    }),
  current_password: yup
    .string()
    .notRequired()
    .optional()
    .when('new_password', (new_password, field) =>
      new_password && new_password.length > 0 && new_password[0] !== ''
        ? field.required('La contraseña actual es requerida')
        : field
    ),
  new_password: yup
    .string()
    .notRequired()
    .optional()
    .when('current_password', (current_password, field) =>
      current_password && current_password.length > 0 && current_password[0] !== ''
        ? field
          .required('La nueva contraseña es requerida')
          .min(6, 'Contraseña debe tener al menos 6 caracteres')
          .matches(/[A-Z]/, 'Contraseña debe tener al menos una letra mayúscula')
          .matches(/\d/, 'Contraseña debe tener al menos un número')
          .matches(/[!@#$%^&*(),.?":{}|<>]/, 'Contraseña debe tener al menos un carácter especial')
        : field
    ),
  new_password_confirm: yup
    .string()
    .oneOf([yup.ref('new_password'), ''], 'Las contraseñas deben coincidir')
    .when('new_password', (new_password, field) =>
      new_password && new_password.length > 0 && new_password[0] !== ''
        ? field.required('Confirmar la nueva contraseña es requerida')
        : field
    ),
  // }, ['current_password', 'new_password']);
}, [['current_password', 'new_password'], ['supplier_phone', 'supplier_phone']]);

function Profile() {
  const dispatch = useDispatch();
  const authUser = useSelector(({ user }) => user.user);
  const profile = useSelector(({ supplier }) => supplier.dataProfile);
  const smallScreen = useMediaQuery('(min-width:600px)');
  const { t } = useTranslation();

  const methods = useForm({
    mode: 'onChange',
    defaultValues,
    resolver: yupResolver(schema),
  });
  const { reset, watch, setValue, getValues, control, onChange, formState, handleSubmit } = methods;
  const { errors } = formState;
  const form = watch();

  const [values, setValues] = useState({
    showPasswordCurrent: false,
    showPasswordNew: false,
    showPasswordConfirm: false,
  });
  const [loading, setLoading] = useState(false);
  const [loadingData, setLoadingData] = useState(false);

  useEffect(() => {
    const fetch = async () => {
      if (!isEmpty(authUser)) {
        setLoadingData(true);
        await dispatch(getProfileById(authUser?.user_id));
        setLoadingData(false);
      }
    };
    fetch();
  }, [authUser]);

  useEffect(() => {
    const fetch = async () => {
      if (profile) {
        reset({
          ...profile,
          current_password: '',
          new_password: '',
          new_password_confirm: '',
        });
      }
    };
    fetch();
  }, [profile]);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleClickShowPassword = (params) => () => {
    setValues({
      ...values,
      [params]: !values[params],
    })
  }

  /**
   * Function to send the new partner information.
   * Checks if the 'PartnersName' field is empty and displays an error if so, otherwise closes the modal.
   * @returns {void} - Returns nothing.
   */
  const handleAction = async (data) => {
    setLoading(true);
    const response = await dispatch(updateProfile(data));
    if (response) {
      await dispatch(showMessage({ message: 'Supplier actualizada', variant: 'success' }));
      await dispatch(getProfileById(authUser?.user_id));

      // Ejecutas el veririfcar jwt
    }
    setLoading(false);
  };

  return (
    <div>
      <div className="w-full flex justify-between items-center mt-4 bg-white p-4 rounded-lg h-16">
        <div className="flex">
          <AccountCircleRoundedIcon
            style={{ color: '#A8AFB4', fontSize: 40 }}
          />
          <div className="ml-2">
            <p className="text-xl font-semibold">{authUser?.user_username}</p>
            <p className="text-xs -mt-1">{t('user')}</p>
          </div>
        </div>
      </div>
      <div className="w-full mt-7 bg-white px-2 py-6 rounded-lg flex flex-col">
        {loadingData ? (
          <CustomLoading />
        ) : (
          <FormProvider {...methods}>
            <div className='w-full'>
              {/* ----------------------------- */}
              <div className='w-full pb-4 px-4'>
                <p className="text-16 font-bold my-2">{t('basic_information')}</p>
                <div className="grid md:grid-cols-4 sm:grid-cols-2 grid-cols-1 gap-3">
                  <div>
                    <Controller
                      name="supplier_company"
                      control={control}
                      render={({ field }) => (
                        <CustomTextField
                          {...field}
                          styles={{ mt: 1, mb: 2, width: '100%' }}
                          id="supplier_company"
                          label="Empresa"
                          // value={form.cli_nombre_empresa}
                          error={!!errors.supplier_company}
                          helperText={errors?.supplier_company?.message}
                          required
                        />
                      )}
                    />
                  </div>
                  <div>
                    <Controller
                      name="supplier_name"
                      control={control}
                      render={({ field }) => (
                        <CustomTextField
                          {...field}
                          styles={{ mt: 1, mb: 2, width: '100%' }}
                          id="supplier_name"
                          label={t('names')}
                          error={!!errors.supplier_name}
                          helperText={errors?.supplier_name?.message}
                          required
                        />
                      )}
                    />
                  </div>
                  <div>
                    <Controller
                      name="supplier_lastname"
                      control={control}
                      render={({ field }) => (
                        <CustomTextField
                          {...field}
                          styles={{ mt: 1, mb: 2, width: '100%' }}
                          id="supplier_lastname"
                          label="Apellidos"
                          // value={form.cli_nombre_empresa}
                          error={!!errors.supplier_lastname}
                          helperText={errors?.supplier_lastname?.message}
                          required
                        />
                      )}
                    />
                  </div>
                  <div>
                    <Controller
                      name="supplier_email"
                      control={control}
                      render={({ field }) => (
                        <CustomTextField
                          {...field}
                          styles={{ mt: 1, mb: 2, width: '100%' }}
                          id="supplier_email"
                          label={t('email')}
                          placeholder="your@email.com"
                          error={!!errors.supplier_email}
                          helperText={errors?.supplier_email?.message}
                          required
                        />
                      )}
                    />
                  </div>
                  <div>
                    <Controller
                      name="supplier_phone"
                      control={control}
                      render={({ field }) => (
                        <CustomTextField
                          {...field}
                          styles={{ mt: 1, mb: 2, width: '100%' }}
                          id="supplier_phone"
                          label={t('cell_phone')}
                          error={!!errors.supplier_phone}
                          helperText={errors?.supplier_phone?.message}
                          required
                        />
                      )}
                    />
                  </div>
                  <div>
                    <Controller
                      name="supplier_address"
                      control={control}
                      render={({ field }) => (
                        <CustomTextField
                          {...field}
                          styles={{ mt: 1, mb: 2, width: '100%' }}
                          id="supplier_address"
                          label={t('address')}
                          error={!!errors.supplier_address}
                          helperText={errors?.supplier_address?.message}
                          required
                        />
                      )}
                    />
                  </div>
                </div>
              </div>
              {/* ----------------------------- */}
              <div className=' w-fullpb-4 px-4'>
                <p className="text-16 font-bold my-2">{t('security')}</p>
                <div className="grid md:grid-cols-4 sm:grid-cols-2 grid-cols-1 gap-3">
                  <div>
                    <Controller
                      name="current_password"
                      control={control}
                      render={({ field }) => (
                        <CustomTextField
                          {...field}
                          autoComplete='off'
                          styles={{ mt: 1, mb: 2, width: '100%' }}
                          type={values.showPasswordCurrent ? "text" : "password"}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                {" "}
                                <IconButton
                                  aria-label="toggle password visibility"
                                  onClick={handleClickShowPassword(
                                    "showPasswordCurrent"
                                  )}
                                  onMouseDown={handleMouseDownPassword}
                                  edge="end"
                                >
                                  {values.showPasswordCurrent ? (
                                    <VisibilityOffRoundedIcon />
                                  ) : (
                                    <VisibilityRoundedIcon />
                                  )}
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                          id="current_password"
                          label={t('password')}
                          error={!!errors.current_password}
                          helperText={errors?.current_password?.message}
                          required
                        />
                      )}
                    />
                  </div>
                  <div>
                    <Controller
                      name="new_password"
                      control={control}
                      render={({ field }) => (
                        <CustomTextField
                          {...field}
                          styles={{ mt: 1, mb: 2, width: '100%' }}
                          type={values.showPasswordNew ? "text" : "password"}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                {" "}
                                <IconButton
                                  aria-label="toggle password visibility"
                                  onClick={handleClickShowPassword(
                                    "showPasswordNew"
                                  )}
                                  onMouseDown={handleMouseDownPassword}
                                  edge="end"
                                >
                                  {values.showPasswordNew ? (
                                    <VisibilityOffRoundedIcon />
                                  ) : (
                                    <VisibilityRoundedIcon />
                                  )}
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                          id="new_password"
                          label={t('new_password')}
                          error={!!errors.new_password}
                          helperText={errors?.new_password?.message}
                          required
                        />
                      )}
                    />
                  </div>
                  <div>
                    <Controller
                      name="new_password_confirm"
                      control={control}
                      render={({ field }) => (
                        <CustomTextField
                          {...field}
                          styles={{ mt: 1, mb: 2, width: '100%' }}
                          type={values.showPasswordConfirm ? "text" : "password"}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                {" "}
                                <IconButton
                                  aria-label="toggle password visibility"
                                  onClick={handleClickShowPassword(
                                    "showPasswordConfirm"
                                  )}
                                  onMouseDown={handleMouseDownPassword}
                                  edge="end"
                                >
                                  {values.showPasswordConfirm ? (
                                    <VisibilityOffRoundedIcon />
                                  ) : (
                                    <VisibilityRoundedIcon />
                                  )}
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                          id="new_password_confirm"
                          label={t('confirm_password')}
                          error={!!errors.new_password_confirm}
                          helperText={errors?.new_password_confirm?.message}
                          required
                        />
                      )}
                    />
                  </div>
                </div>
              </div>
              {/* ----------------------------- */}
            </div>
            <div className="flex justify-end my-4 items-center">
              {loading ? (
                <CircularProgress
                  style={{ color: '#4575D5', marginRight: 4 }}
                  size={24}
                />
              ) : (
                <CustomButton
                  label="Actualizar"
                  typebutton={2}
                  onClick={handleSubmit(handleAction)}
                />
              )}
            </div>
          </FormProvider>
        )}
      </div>
    </div>
  );
}

export default Profile;
