import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import md5 from 'md5';

import {
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  Slide,
  IconButton,
  InputAdornment,

  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  FormControl,
  FormHelperText,
  Link,
} from '@mui/material';
import { makeStyles } from '@mui/styles';

import { useForm, Controller, FormProvider } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { es } from 'date-fns/locale';
import { format, parseISO, parse, isValid } from 'date-fns';

import TodayRoundedIcon from '@mui/icons-material/TodayRounded';
import DeleteSweepRoundedIcon from '@mui/icons-material/DeleteSweepRounded';

import CustomButton from '@components/CustomButton';
import CustomTextField from '@components/CustomTextField';
import CustomAutocomplete from '@components/CustomAutocomplete';
import CustomUploadFile from '@components/CustomUploadFile';
import CustomTableHead from '@components/CustomTableHead';

import {
  create,
  update,
  getActivityType,
  createSellerActivityFile,
  getSellerActivityFile,
  setCleanFile,
  deleteSellerActivityFile
} from '../../../store/sellerActivitySlice';
import {
  getSellers,
} from '../../../store/sellerSlice';
import {
  getBuyers,
} from '../../../store/buyerSlice';
import { showMessage } from '../../../store/messageSlice';
import API from '../../../configs/urlsConfig';

/**
 * Transition is a function that creates a transition component using React's forwardRef function.
 *
 * @param {object} props - The properties of the Transition component.
 * @param {React.Ref} ref - Ref that is passed to the Slide component.
 * @returns {React.Component} - Transition component using Slide.
 */
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles((theme) => ({
  popper: {
    '& .MuiPopper-root': { zIndex: 9999 },
  },
  dialog: {
    // width: "100%",
    // backgroundColor: 'rgba(80, 80, 80, .5)',
    "& .MuiDialog-paperWidthSm": {
      maxWidth: 600,
      width: '100%',
    },
    // "& .MuiDialog-paperWidthMd": {
    //   maxWidth: 600,
    //   width: '100%',
    //   // backgroundColor: 'white',
    // },
  },
}));

const defaultValues = {
  ass_final_file: null,
  ass_description: '',
};

const schema = yup.object().shape({
  ass_final_file: yup.mixed().required('Archivo es requerido'),
  ass_description: yup.string().required('Descripción es requerido')
});

/**
 * Props for the CustomDialog component.
 * @property {boolean} open - Determines whether the dialog is open or closed.
 * @property {Function} handleClose - Callback function to handle the closing of the dialog.
 * @property {Function} handleRefresh - Callback function to handle the closing of the dialog.
 * @property {string} type - The type of operation ('create' or 'edit') the dialog is performing.
 * @property {Object} item - Object containing information about the partner being edited.
 */
function CustomDialog(props) {
  const { open, handleClose, type, item, handleRefresh } = props;
  const dispatch = useDispatch();
  const classes = useStyles();

  const [items, setItems] = useState([]);
  const [loading, setLoading] = useState(false);

  const methods = useForm({
    mode: 'onChange',
    defaultValues,
    resolver: yupResolver(schema),
  });
  const { reset, control, formState, handleSubmit, setValue } = methods;
  const { errors } = formState;

  //-------------------------------------------
  const initDialog = useCallback(async () => {
    reset({
      ...item,
      seac_seller_id: {
        label: `${item.fk_seller?.seller_name}`,
        value: item.seac_seller_id,
      },
      seac_date: parseISO(item?.seac_date),
      seac_seller_activity_type_id: {
        label: `${item.fk_seller_activity_type?.sat_name}`,
        value: item.seac_seller_activity_type_id,
      },
      seac_buyer_id: {
        label: `${item.fk_buyer?.buyer_company}`,
        value: item.seac_buyer_id,
      },
    });
  });

  useEffect(() => {
    const fetch = async () => {
      if (type === 'edit') {
        initDialog();
      }
    };
    fetch();
  }, [type, reset, item]);

  /**
  * Function to clean the information
  */
  const cleanData = () => {
    reset(defaultValues);
  };

  // --------------------------------------------
  const handleUploadChange = async (eventFile) => {
    const file = eventFile[0];
    if (!file) {
      return;
    }
    const formatType =
      file.type === 'application/pdf'
        ? 'pdf'
        : file.type === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
          ? 'docx'
          : file.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
            ? 'xlsx'
            : file.type === 'application/vnd.openxmlformats-officedocument.presentationml.presentation'
              ? 'xlspptxx'
              : file.type === 'image/png'
                ? 'png'
                : 'jpeg';
    const data = {
      file,
      hash_archivo: `${md5(Date.now())}.${formatType}`,
    };
    setValue('ass_final_file', data);
    errors.ass_final_file = false;
    // ----------------------------------------------------------------------
    // const reader = new FileReader();
    // reader.readAsBinaryString(file);
    // reader.onload = async () => {
    //   const formatType =
    //     file.type === 'application/pdf'
    //       ? 'pdf'
    //       : file.type === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
    //         ? 'docx'
    //         : file.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
    //           ? 'xlsx'
    //           : file.type === 'application/vnd.openxmlformats-officedocument.presentationml.presentation'
    //             ? 'xlspptxx'
    //             : file.type === 'image/png'
    //               ? 'png'
    //               : 'jpeg';
    //   const data = {
    //     file,
    //     name_archivo: `${md5(Date.now())}.${formatType}`,
    //     url_base64: `data:${file.type};base64,${btoa(reader.result)}`,
    //   };
    //   setValue('ass_final_file', data);
    //   errors.ass_final_file = false;
    // };
    // reader.onerror = function (error) {
    //   console.log('error on load image', error);
    // };
    // ----------------------------------------------------------------------
  };

  const handleAction = async (data) => {
    setLoading(true);
    const formData = new FormData();
    formData.append('file', data.ass_final_file.file);
    formData.append('hash_archivo', data.ass_final_file.hash_archivo);
    formData.append('ass_description', data.ass_description);

    const response = await dispatch(createSellerActivityFile(formData));
    if (response) {
      await dispatch(showMessage({ message: 'Seller activities actualizada', variant: 'success' }));
      cleanData();
      setLoading(false);
      handleClose();
      handleRefresh();
    } else {
      setLoading(false);
    }
  }

  return (
    <Dialog open={open} TransitionComponent={Transition} className={classes.dialog}>
      <DialogTitle className="bg-gray-100 flex items-center justify-center rounded-t-lg">
        <p className="font-bold text-xl">
          Detalle service
        </p>
      </DialogTitle>
      <DialogContent /*sx={{ width: '800px' }}*/ >
        <FormProvider {...methods}>
          <div className='mb-5'>
            <p className="text-16 font-bold my-2">Información</p>
            <div className="mb-0">
              <p className="text-14">Vendedor: <span>({item.sese_seller_id}) - {item.fk_seller?.seller_name}</span></p>
              <p className="text-14">Servicio: <span>({item.sese_service_id}) - {item.fk_service?.service_name}</span></p>
            </div>
          </div>
          <div className=''>
            <p className="text-16 font-bold my-2">Cargar de archivos</p>
            <div className="mt-4">
              <div className="grid xl:grid-cols-1 grid-cols-2 gap-1">
                <div className='xl:col-span-1 col-span-2'>
                  <div className='mb-2'>
                    <Typography color="#262940" className="text-16">
                      Adjuntar documento de soporte{' '}
                      <span style={{ color: '#B7BECD' }}>(jpg, jpeg, png, docx, xlsx, pptx, pdf):</span>
                    </Typography>
                  </div>
                  <Controller
                    name="ass_final_file"
                    control={control}
                    render={({ field }) => (
                      <CustomUploadFile
                        {...field}
                        id="ass_final_file"
                        handleUploadChange={(e) => handleUploadChange(e)}
                        accept={{
                          'application/pdf': ['.pdf'],
                          'image/*': ['.jpg', '.jpeg', '.png'],
                          'application/vnd.openxmlformats-officedocument.wordprocessingml.document': [
                            '.doc',
                            '.docx',
                          ],
                          'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ['.xlsx'],
                          'application/vnd.openxmlformats-officedocument.presentationml.presentation': ['.pptx'],
                        }}
                        required={!!errors.ass_final_file}
                      />
                    )}
                  />
                  <div className=''>
                    <FormControl error={!!errors.ass_final_file} className="w-full">
                      <FormHelperText style={{ marginLeft: 0 }}>{errors?.ass_final_file?.message}</FormHelperText>
                    </FormControl>
                  </div>
                </div>
                <div className='xl:col-span-1 col-span-2'>
                  <Controller
                    control={control}
                    name="ass_description"
                    render={({ field }) => (
                      <CustomTextField
                        {...field}
                        styles={{ mt: 1, mb: 2, width: '100%' }}
                        id="ass_description"
                        label="Descripción"
                        multiline
                        rows={4}
                        maxRows={4}
                        error={!!errors.ass_description}
                        helperText={errors?.ass_description?.message}
                        required
                      />
                    )}
                  />
                </div>
              </div>
            </div>
          </div>
          {/* ---------------------- */}
          <div className="flex justify-end my-4 items-center">
            <p
              className="mx-4 underline text-base text-gray-600 cursor-pointer"
              onClick={() => {
                handleClose();
                setLoading(false);
                cleanData();
              }}
            >
              Cancel
            </p>
            {loading ? (
              <CircularProgress
                style={{ color: '#4575D5', marginRight: 4 }}
                size={24}
              />
            ) : (
              <CustomButton
                label="Guardar"
                typebutton={2}
                onClick={handleSubmit(handleAction)}
              />
            )}
          </div>
          {/* ---------------------- */}
        </FormProvider>
      </DialogContent>
    </Dialog>
  );
}

export default CustomDialog;
