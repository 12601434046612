import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from 'i18next-browser-languagedetector';
import HttpBackend from 'i18next-http-backend';

i18n
  .use(HttpBackend) // Plugin para cargar traducciones desde archivos JSON
  .use(LanguageDetector) // Plugin para detectar el idioma del usuario
  .use(initReactI18next) // Plugin para integrar i18next con React
  .init({
    // the translations
    // (tip move them in a JSON file and import them,
    // or even better, manage them via a UI: https://react.i18next.com/guides/multiple-translation-files#manage-your-translations-with-a-management-gui)
    // resources: {
    //   en: {
    //     translation: require('./locales/en.json')
    //   },
    //   ar: {
    //     translation: require('./locales/ar.json')
    //   },
    //   tr: {
    //     translation: require('./locales/tr.json')
    //   }
    // },
    lng: "en-US", // si utiliza un detector de idioma, no defina la opción lng
    fallbackLng: "en-US",  // Idioma por defecto si no se detecta ninguno
    debug: false, // Habilitar mensajes de depuración en la consola
    interpolation: {
      escapeValue: false // react already safes from xss => https://www.i18next.com/translation-function/interpolation#unescape
    },
    backend: {
      loadPath: '/locales/{{lng}}.json', // Ruta para cargar las traducciones
    },
  });

export default i18n;