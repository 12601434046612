import axios from 'axios';
import { createSlice } from '@reduxjs/toolkit';
import API from '../configs/urlsConfig';
import { showMessage } from './messageSlice';

export const getServicesGroup = () => async (dispatch) => {
  try {
    const URL = `${API.baseUrl}/${API.endpoints.services_group}/lista`;
    const result = await axios.get(URL);
    await dispatch(setServicesGroupList(result.data));
    return true;
  } catch (error) {
    throw new Error(`*** REDUX -> getServicesGroup *** ${error}`);
  }
};

export const getAll = (page, per_page, orderBy, order, params) => async (dispatch) => {
  try {
    const queryString = `?page=${page}&rows=${per_page}&order_field=${orderBy}&order_type=${order}&filters=${JSON.stringify(params)}`;
    const URL = `${API.baseUrl}/${API.endpoints.services_group}`;
    const result = await axios.get(URL + queryString);
    await dispatch(setDataList(result.data));
    return result;
  } catch (error) {
    throw new Error(`*** REDUX -> getAll *** ${error}`);
  }
};

export const create = (data) => async (dispatch) => {
  try {
    const URL = `${API.baseUrl}/${API.endpoints.services_group}`;
    await axios.post(URL, data);
    return true;
  } catch (error) {
    throw new Error(`*** REDUX -> create *** ${error}`);
  }
};

export const update = (data, id) => async (dispatch) => {
  try {
    const URL = `${API.baseUrl}/${API.endpoints.services_group}/${id}`;
    await axios.put(URL, data);
    return true;
  } catch (error) {
    throw new Error(`*** REDUX -> update *** ${error}`);
  }
};

const serviceGroupSlice = createSlice({
  name: 'serGroup',
  initialState: {
    enabledIntegration: false,
    dataList: [],
    servicesGroupList: [],
    page: 0,
    rowsPerPage: 10,
    total: 0,
  },
  reducers: {
    setDataList: (state, action) => {
      state.dataList = action.payload.rows;
      state.total = action.payload.count;
    },
    setServicesGroupList: (state, action) => {
      state.servicesGroupList = action.payload;
    },
    setRowsPerPage: (state, action) => {
      state.rowsPerPage = action.payload;
    },
    setPage: (state, action) => {
      state.page = action.payload;
    },
  }
});

export const { setDataList, setServicesGroupList, setRowsPerPage, setPage, setTotalRows } =
serviceGroupSlice.actions;

export default serviceGroupSlice.reducer;
