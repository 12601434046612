import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { useAuth } from './AuthContext';

const AuthGuard = ({ children }) => {
  const { getAccessToken, /*token*/ } = useAuth();
  const  token = getAccessToken();
  const location = useLocation();

  // Check if the user is authenticated
  if (!token) {
    // If not authenticated, redirect to the login page
    return <Navigate to="/sign-in" state={{ from: location }} />;
  }

  // If authenticated, render the child routes
  return children;
};

export default AuthGuard;