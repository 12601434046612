import * as React from 'react';
import PropTypes from 'prop-types';
import TextField from '@mui/material/TextField';

const propTypes = {
  label: PropTypes.string,
  placeholder: PropTypes.string,
  onchange: PropTypes.func,
  value: PropTypes.string,
  styles: PropTypes.any
};

const defaultProps = {
  label: '',
  placeholder: '',
  value: '',
  styles: ''
};

/**
 * Reusable component that encapsulates a Material-UI text field.
 * @param {object} props - Properties of the component.
 * @param {string} props.label - Text field label.
 * @param {string} props.placeholder - Placeholder text of the text field.
 * @param {function} props.onchange - Callback function to handle value changes.
 * @param {string} props.value - Current value of the text field.
 * @param {object} props.styles - Additional styles to apply to the text field.
 * @returns {JSX.Element} - Element of the custom text field.
 */
function CustomTextField(props) {
  const { styles, onChange, value} = props;

  return (
    <TextField
      {...props}
      value={value}
      onChange={onChange}
      size="small"
      sx={{
        ...styles,
        // minWidth: 200,
        '& label.Mui-focused': {
          color: '#000'
        },
        '& .MuiOutlinedInput-root': {
          '&:hover fieldset': {
            borderColor: '#000'
          },
          '&.Mui-focused fieldset': {
            borderColor: '#000',
            borderWidth: 1
          }
        },
        '& .MuiFormHelperText-root': {
          marginLeft: 0,
          marginRight: 0,
        }
      }}
    />
  );
}

CustomTextField.propTypes = propTypes;
CustomTextField.defaultProps = defaultProps;

export default CustomTextField;
