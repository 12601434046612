import { Typography } from '@mui/material';
import { showMessage } from '../../store/messageSlice';
import { isEmpty } from 'lodash';
import React from 'react';
import { useDropzone } from 'react-dropzone';
import { useDispatch } from 'react-redux';

const CustomUploadFile = (props) => {
    const dispatch = useDispatch();
    const { value, handleUploadChange, accept, disabled, required } = props;

    const { getRootProps, getInputProps } = useDropzone({
        // accept:  'image/jpeg, image/png, .doc, .docx, .xlsx, application/pdf',
        accept:
            accept !== undefined
                ? accept
                : {
                    'image/*': ['.jpg', '.jpeg', '.png'],
                    'application/pdf': ['.pdf'],
                    'application/vnd.openxmlformats-officedocument.wordprocessingml.document': [
                        '.doc',
                        '.docx',
                    ],
                    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ['.xlsx'],
                    'application/vnd.openxmlformats-officedocument.presentationml.presentation': ['.pptx'],
                },

        maxSize: 5000000,
        disabled: disabled,
        onDropRejected: async () => {
            await dispatch(
                showMessage({
                    message: `Los archivos que adjuntes no deben superar los 5MB`,
                    variant: 'error',
                })
            );
        },
        onDrop: (acceptedValue) => handleUploadChange(acceptedValue),
    });

    const showFile = (
        <div className="flex p-4 my-4 " style={{ backgroundColor: '#E6F0FA' }}>
            <div
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    whiteSpace: 'pre',
                    textOverflow: 'ellipsis',
                    overflow: 'hidden',
                }}
            >
                <div style={{ color: '#023E73' }} className="text-[13px] font-normal text-ellipsis">
                    {value?.file?.name}
                </div>
            </div>
            <div className="flex justify-center"></div>
        </div>
    );

    return (
        <div>
            <div {...getRootProps()}>
                <input {...getInputProps()} />
                <div
                    style={{
                        borderColor: required ? '#d32f2f' : '#BDD7EF',
                        maxHeight: 100,
                        height: 100,
                        backgroundColor: disabled ? '#F7F8FC' : '#fff',
                        cursor: disabled ? 'auto' : 'pointer',
                    }}
                    className="flex justify-center items-center rounded-8 border-dashed  border-2 w-288 "
                >
                    {isEmpty(value) ? (
                        <div>
                            <Typography className="text-14 my-12 mx-24 text-center" style={{ color: '#7F9BB4' }}>
                                <span className="font-bold">Suelta el archivo </span>
                                aquí o <span className="font-bold"> haz clic</span> para adjuntar
                            </Typography>
                        </div>
                    ) : (
                        <div
                            className="flex flex-col justify-center items-center"
                            style={{
                                whiteSpace: 'nowrap',
                                textOverflow: 'ellipsis',
                                overflow: 'hidden',
                            }}
                        >
                            <div style={{ maxHeight: 128, maxWidth: 270 }}>
                                {!isEmpty(value) && (
                                    <div className=" flex justify-center">
                                        <div className="flex flex-col  overflow-auto">{showFile}</div>
                                    </div>
                                )}
                            </div>

                            {/* <Typography className="text-14" style={{ color: '#7F9BB4' }}>
                                <span className="font-bold">haz clic</span> para adjuntar
                            </Typography> */}
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default CustomUploadFile;
