import './index.css';
import DatePicker from 'react-datepicker';
import TodayRoundedIcon from '@mui/icons-material/TodayRounded';
import 'react-datepicker/dist/react-datepicker.css';

const defaultProps = {
  selected: new Date()
};

/**
 * CustomDatePicker is a React component that encapsulates the DatePicker component to select and display dates in a custom format.
 * to select and display dates in a custom format.
 * @param {Object} props - Properties of the component.
 * @param {Date} props.selected - Selected date.
 * @param {function} props.onChange - Function called when the selected date changes.
 * @param {string} [props.dateFormat] - Date format to display. Default, 'yyyy-MM-dd'.
 * @returns {JSX.Element} - JSX element representing the CustomDatePicker component.
 */

function CustomDatePicker(props) {
  const { selected, onChange, dateFormat } = props;

  return (
    <DatePicker
      {...props}
      showIcon
      selected={selected}
      onChange={onChange}
      wrapperClassName="customdatepicker"
      dateFormat={dateFormat ? dateFormat : 'yyyy-MM-dd'}
      icon={<TodayRoundedIcon />}
    />
  );
}

CustomDatePicker.defaultProps = defaultProps;

export default CustomDatePicker;
