import axios from "axios";
import { createContext, useContext, useEffect, useMemo, useState } from "react";
import { jwtDecode } from "jwt-decode";

import CustomSplashScreen from '@components/CustomSplashScreen';
import API from '../../configs/urlsConfig';
import { useDispatch } from 'react-redux';
import { showMessage } from '../../store/messageSlice';
import { setUser } from '../../store/userSlice';

const AuthContext = createContext();

const AuthProvider = ({ children }) => {

  console.log(' ---- initialization ----');
  const dispatch = useDispatch();
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  // Set session
  const setSession = (accessToken) => {
    if (accessToken) {
      localStorage.setItem('jwt_access_token', accessToken);
      axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
    }
  };

  // Reset session
  const resetSession = () => {
    localStorage.removeItem('jwt_access_token');
    delete axios.defaults.headers.common['Authorization'];
  };

  // Set modules
  const setModules = (modules) => {
    if (modules) {
      localStorage.setItem('modules', modules);
    }
  };

  // Reset modules
  const resetModules = () => {
    localStorage.removeItem('modules');
  };

  // Get session
  const getAccessToken = () => {
    return localStorage.getItem('jwt_access_token');
  };

  // Get modules
  const getModules = () => {
    return JSON.parse(localStorage.getItem('modules'));
  };

  /**
  * Handle sign-in success
  */
  const handleSuccess = ((userData, accessToken, modules) => {
    setSession(accessToken);
    setIsAuthenticated(true);
    dispatch(setUser(userData));
    setModules(JSON.stringify(modules));
  });

  const handleFailure = async (error) => {
    resetSession();
    resetModules();
    setIsAuthenticated(false);
    dispatch(setUser({}));
    const errorMessage = error?.response?.data?.message || error.message;
    await dispatch(showMessage({ message: errorMessage, variant: 'error' }));
  };

  const signIn = async (credentials) => {
    try {
      const response = await axios.post(`${API.baseUrl}/${API.endpoints.authenticate}`, credentials);
      const userData = response?.data?.auth_info?.user;
      const accessToken = response?.data?.auth_info?.access_token;
      const modules = response?.data?.auth_info?.modules;
      handleSuccess(userData, accessToken, modules);
      return userData;
    } catch (error) {
      handleFailure(error);
      return error;
    }
  };

  const signOut = () => {
    resetSession();
    resetModules();
    setIsAuthenticated(false);
    dispatch(setUser({}));
  };

  // Check if the token is valid
  const isTokenValid = (accessToken) => {
    if (accessToken) {
      try {
        const decoded = jwtDecode(accessToken);
        const currentTime = Date.now() / 1000;
        return decoded.exp > currentTime;
      } catch {
        return false;
      }
    }
    return false;
  };

  // Attempt auto-login on mount
  useEffect(() => {
    const attemptAutoLogin = async () => {
      const accessToken = getAccessToken();
      if (accessToken && isTokenValid(accessToken)) {
        try {
          setIsLoading(true);
          const response = await axios.get(`${API.baseUrl}/${API.endpoints.authenticate}/jwt`, {
            headers: { Authorization: `Bearer ${accessToken}` }
          });
          const userData = response?.data?.auth_info.user;
          const modules = response?.data?.auth_info.modules;
          handleSuccess(userData, response?.data?.auth_info.access_token, modules);
        } catch (error) {
          handleFailure(error);
        } finally {
          setIsLoading(false);
        }
      } else {
        resetSession();
        resetModules();
        setIsLoading(false);
      }
    };

    if (!isAuthenticated) {
      attemptAutoLogin();
    }
  }, [
    isTokenValid,
    setSession,
    setModules,
    handleSuccess,
    handleFailure,
    getAccessToken,
    isAuthenticated,
    resetSession,
    resetModules
  ]);

  // Memoized value of the authentication context
  const contextValue = useMemo(
    () => ({
      signIn,
      signOut,
      isAuthenticated,
      isLoading,
      getAccessToken,
      getModules,
    }),
    [signIn, signOut, isAuthenticated, isLoading, getAccessToken, getModules]
  );

  return (
    isLoading ? (
      <CustomSplashScreen />
    ) : (
      <AuthContext.Provider value={contextValue}>{children}</AuthContext.Provider>
    )
  );
};

export const useAuth = () => {
  return useContext(AuthContext);
};

export default AuthProvider;