
import React from 'react';
import { useTranslation } from 'react-i18next';

import { Grid, Container } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
  typography: {
    textAlign: "left",
    fontSize: 12,
    marginBottom: 8,
    marginTop: 16,
    fontWeight: 500,
    color: '#99A0AB'
  },
  customText: {

    width: '100%',
    '& .MuiFormHelperText-root': {
      color: 'red',
      marginTop: 4,
      fontWeight: 500,
      textAlign: 'right',
    },
  },
});

function Index() {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Container sx={{ mt: 10, mb: 4 }}>
      <Grid
        container
        justifyContent='center'
      >
        <Grid
          item
          xs={11} sm={7} md={5}
          style={{ marginTop: 0 }}
        >
          <div className="">
            <h1>{t('welcome')}</h1>
          </div>
        </Grid>
      </Grid>
    </Container>
  );
}
export default Index;