import * as React from 'react';
import { CircularProgress } from '@mui/material';

function CustomLoading() {
  return (
    <div className="w-full flex justify-center my-12">
      <CircularProgress size={40} style={{ color: '#4575D5' }} />
    </div>
  );
}

export default CustomLoading;
