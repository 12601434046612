import axios from 'axios';
import { createSlice } from '@reduxjs/toolkit';
import API from '../configs/urlsConfig';
import { showMessage } from './messageSlice';

// *** ROL SELLER ***
export const getActivitySellerById = (userId, service) => async (dispatch) => {
    try {
        const URL = `${API.baseUrl}/${API.endpoints.seller_tracking}/user/${userId}?service_id=${service}`;
        const result = await axios.get(URL);
        await dispatch(setDataById(result.data));
        return result;
    } catch (error) {
        throw new Error(`*** REDUX -> getActivitySellerById *** ${error}`);
    }
};

// *** ROL SELLER ***
export const getServiceDocumentById = (service) => async (dispatch) => {
    try {
        const URL = `${API.baseUrl}/${API.endpoints.seller_tracking}/service_document/${service}`;
        const result = await axios.get(URL);
        await dispatch(setServiceDocuments(result.data));
        return result;
    } catch (error) {
        throw new Error(`*** REDUX -> getServiceDocumentById *** ${error}`);
    }
};

// *** ROL SELLER ***
export const getActivityFile = (id) => async (dispatch) => {
    try {
        const URL = `${API.baseUrl}/${API.endpoints.seller_tracking}/activity_file/${id}`;
        const result = await axios.get(URL);
        await dispatch(setActivityFiles(result.data));
        return true;
    } catch (error) {
        throw new Error(`*** REDUX -> getActivityFile *** ${error}`);
    }
};

// *** ROL SELLER ***
export const createActivityFile = (data) => async (dispatch) => {
    try {
        const URL = `${API.baseUrl}/${API.endpoints.seller_tracking}/activity_file`;
        await axios.post(URL, data, { headers: { 'Content-Type': 'multipart/form-data' } });
        return true;
    } catch (error) {
        throw new Error(`*** REDUX -> createActivityFile *** ${error}`);
    }
};

// *** ROL SELLER ***
export const deleteActivityFile = (data) => async (dispatch) => {
    try {
        const URL = `${API.baseUrl}/${API.endpoints.seller_tracking}/activity_file_delete`;
        await axios.post(URL, data);
        return true;
    } catch (error) {
        throw new Error(`*** REDUX -> deleteActivityFile *** ${error}`);
    }
};

// *** ROL SELLER ***
export const saveReceptionInfo = (data) => async (dispatch) => {
    try {
        const URL = `${API.baseUrl}/${API.endpoints.seller_tracking}/reception_infomation`;
        await axios.post(URL, data);
        return true;
    } catch (error) {
        throw new Error(`*** REDUX -> saveReceptionInfo *** ${error}`);
    }
};

const activityServicesSlice = createSlice({
    name: 'activityServ',
    initialState: {
        enabledIntegration: false,
        dataById: {},
        serviceDocuments: [],
        activityFiles: [],
    },
    reducers: {
        setCleanActivityServ: (state) => {
            state.dataById = {};
        },
        setDataById: (state, action) => {
            state.dataById = action.payload;
        },
        setServiceDocuments: (state, action) => {
            state.serviceDocuments = action.payload;
        },
        setActivityFiles: (state, action) => {
            state.activityFiles = action.payload;
        },
    }
});

export const { setCleanActivityServ, setDataById, setServiceDocuments, setActivityFiles } =
    activityServicesSlice.actions;

export default activityServicesSlice.reducer;
