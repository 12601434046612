import * as React from 'react';
import PropTypes from 'prop-types';
import TablePagination from '@mui/material/TablePagination';

const propTypes = {
    page: PropTypes.number,
    rowsPerPage: PropTypes.number,
    onPageChange: PropTypes.func,
    onRowsPerPageChange: PropTypes.func,
    count: PropTypes.number
};

const defaultProps = {
    page: 0,
    rowsPerPage: 10,
    onPageChange: () => {},
    onRowsPerPageChange: () => {},
};

function CustomTablePagination(props) {
    const { page, onPageChange, rowsPerPage, onRowsPerPageChange, count } = props;

    return (
        <TablePagination
            component="div"
            count={count}
            page={page}
            onPageChange={onPageChange}
            rowsPerPage={rowsPerPage}
            onRowsPerPageChange={onRowsPerPageChange}
            sx={{
                '& .MuiToolbar-root.MuiTablePagination-toolbar':{
                    minHeight:'30px !important',
                },
                '& .MuiButtonBase-root.MuiIconButton-root':{
                    padding:0
                }
            }}
        />
    );
}

CustomTablePagination.propTypes = propTypes;
CustomTablePagination.defaultProps = defaultProps;

export default CustomTablePagination;