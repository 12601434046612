import React, { useState, useEffect } from "react";

import Button from '@mui/material/Button';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import MenuItem from '@mui/material/MenuItem';
import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';
import { Link } from 'react-router-dom';
import { changeLanguage } from '../../store/i18nSlice';
import { isEmpty } from 'ramda';

import { useDispatch, useSelector } from 'react-redux';
import { useAuth } from '../../auth/services/AuthContext';

/**
 * The language switcher.
 */
function LanguageSwitcher() {
	const { getModules /* token */ } = useAuth();

	const currentLanguage = useSelector(({ i18n }) => i18n.language);
	const languages = useSelector(({ i18n }) => i18n.languages);
	const selectLng = languages.find(lng => lng.id === currentLanguage);
	
	const authUser = useSelector(({ user }) => user.user);
	const [menu, setMenu] = useState(null);
	const dispatch = useDispatch();

	useEffect(() => {
		if (!isEmpty(authUser)){
			dispatch(changeLanguage(authUser.countryLanguage ? authUser.countryLanguage : currentLanguage));
		}
	}, [authUser])


	const langMenuClick = (event) => {
		setMenu(event.currentTarget);
	};

	const langMenuClose = () => {
		setMenu(null);
	};

	function handleLanguageChange(lng) {
		dispatch(changeLanguage(lng.id));
		langMenuClose();
	}

	return (
		<div className="mr-5">
			<Button
				className="w-50"
				// onClick={langMenuClick}
			>
				<img
					className="mx-4 min-w-8"
					src={`assets/flags/${selectLng.flag}.svg`}
					alt={selectLng.title}
				/>

				<Typography
					className="mx-4 font-semibold uppercase"
					color="text.secondary"
				>
					{selectLng.show}
				</Typography>
			</Button>
			<Popover
				open={Boolean(menu)}
				anchorEl={menu}
				onClose={langMenuClose}
				anchorOrigin={{
					vertical: 'bottom',
					horizontal: 'center'
				}}
				transformOrigin={{
					vertical: 'top',
					horizontal: 'center'
				}}
				classes={{
					paper: 'py-4'
				}}
			>
				{languages.map((lng) => (
					<MenuItem
						key={lng.id}
						onClick={() => handleLanguageChange(lng)}
					>
						<ListItemIcon className="min-w-40">
							<img
								className="min-w-8 max-w-8"
								src={`assets/flags/${lng.flag}.svg`}
								alt={lng.title}
							/>
						</ListItemIcon>
						<ListItemText primary={lng.title} />
					</MenuItem>
				))}

				{/* <MenuItem
					component={Link}
					to="/documentation/configuration/multi-language"
					onClick={langMenuClose}
					role="button"
				>
					<ListItemText primary="Learn More" />
				</MenuItem> */}
			</Popover>
		</div>
	);
}

export default LanguageSwitcher;
