import {
  Box,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel
} from '@mui/material';
import { visuallyHidden } from '@mui/utils';

/**
 * @param order - order type asc or desc
 * @param orderBy  - item by which the information will be organized
 * @param onRequestSort  - function to select the item by which the info will be arranged
 * @returns table header with options for organizing the data
 */
function CustomTableHead(props) {
  const { order, orderBy, onRequestSort, headCells } = props;

  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  const tableCellStyles = {
    backgroundColor: 'white',
    color: '#121926',
    fontWeight: 'bold',
    fontSize: 15,
    padding: '10px 5px',
    '& .MuiButtonBase-root.MuiTableSortLabel-root.Mui-active': {
      color: 'black',
      '& .MuiTableSortLabel-icon': {
        color: '#121926'
      }
    },
    '& .MuiButtonBase-root.MuiTableSortLabel-root:hover': {
      color: '#121926'
    },
    '& .MuiTableCell-root': {
      lineHeight: '1rem',
    }
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.align ? headCell.align : 'left'}
            padding="normal"
            sortDirection={orderBy === headCell.id ? order : false}
            sx={{ ...tableCellStyles, width: headCell.width }}
          >
            {headCell.order ? (
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : 'asc'}
                onClick={createSortHandler(headCell.id)}
              >
                <div dangerouslySetInnerHTML={{ __html: headCell.label }}/>
                {orderBy === headCell.id ? (
                  <Box component="span" sx={visuallyHidden}>
                    {order === 'desc'
                      ? 'sorted descending'
                      : 'sorted ascending'}
                  </Box>
                ) : null}
              </TableSortLabel>
            ) : (
              <div dangerouslySetInnerHTML={{ __html: headCell.label }} />
            )}
          </TableCell>
        ))}
      </TableRow>
      {/* ----------------- */}
      {/* <TableRow style={{ backgroundColor: '#F7F8FC' }} className="h-36">
        {headCells.map((row) => {
          return (
            <TableCell
              className="px-4 md:px-16 py-0"
              key={row.id}
              align={row.align}
              padding={row.disablePadding ? 'none' : 'normal'}
              sortDirection={props.order.id === row.id ? props.order.direction : false}
              style={{ width: row.width }}
            >
              {row.id === 'efm_id' && (
                <Controller
                  name="efm_id"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      className={classes.textField}
                      style={{ maxWidth: 45 }}
                      id="efm_id"
                      name="efm_id"
                      onKeyPress={handledEnter}
                      onChange={handledChangeInput}
                    />
                  )}
                />
              )}
              {row.id == 'efm_fecha_creacion' && (
                <Controller
                  control={control}
                  name="efm_fecha_creacion"
                  render={({ field }) => (
                    <DatePicker
                      {...field}
                      clearable
                      // disableFuture
                      value={field.value}
                      inputFormat="yyyy-MM-dd"
                      onAccept={() => handledEnter('random')}
                      onChange={handledChangeDate}
                      renderInput={(_props) => (
                        <TextField
                          id="efm_fecha_creacion"
                          className={classes.textField}
                          style={{ background: '#fff' }}
                          {..._props}
                        />
                      )}
                      components={{
                        OpenPickerIcon: () => (
                          <FuseSvgIcon className="text-48 mr-8" size={14} color="#651DFF">
                            feather:calendar
                          </FuseSvgIcon>
                        ),
                      }}
                    />
                  )}
                />
              )}
              {row.id === 'efm_nombre' && (
                <Controller
                  name="efm_nombre"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      className={classes.textField}
                      style={{ maxWidth: 200 }}
                      id="efm_nombre"
                      name="efm_nombre"
                      onKeyPress={handledEnter}
                      onChange={handledChangeInput}
                    />
                  )}
                />
              )}
              {row.id === 'efm_descripcion' && (
                <Controller
                  name="efm_descripcion"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      className={classes.textField}
                      style={{ maxWidth: 400 }}
                      id="efm_descripcion"
                      name="efm_descripcion"
                      onKeyPress={handledEnter}
                      onChange={handledChangeInput}
                    />
                  )}
                />
              )}
              {row.id === 'efm_orden' && (
                <Controller
                  name="efm_orden"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      className={classes.textField}
                      style={{ maxWidth: 400 }}
                      id="efm_orden"
                      name="efm_orden"
                      onKeyPress={handledEnter}
                      onChange={handledChangeInput}
                    />
                  )}
                />
              )}
              {row.id === 'efm_estado' && (
                <Controller
                  name="efm_estado"
                  control={control}
                  render={({ field }) => (
                    <Select
                      {...field}
                      className={classes.textField}
                      style={{ maxWidth: 300 }}
                      id="efm_estado"
                      name="efm_estado"
                      onChange={handledChangeInput}
                      sx={{
                        minHeight: 25,
                      }}
                    >
                      {optionState.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </Select>
                  )}
                />
              )}
            </TableCell>
          );
        }, this)}
      </TableRow> */}
    </TableHead>
  );
}

export default CustomTableHead;
