import { configureStore } from '@reduxjs/toolkit';

import i18nSlice from './i18nSlice';
import showMessage from './messageSlice';
import userSlice from './userSlice';
import sellerSlice from './sellerSlice';
import documentTypeSlice from './documentTypeSlice';
import citySlice from './citySlice';
import stateSlice from './stateSlice';
import serviceGroupSlice from './serviceGroupSlice';
import roleSlice from './roleSlice';
import serviceSlice from './serviceSlice';
import questionSlice from './questionSlice';
import answerSlice from './answerSlice';
import personSlice from './personSlice';
import jobSlice from './jobSlice';
import diagnosticSlice from './diagnosticSlice';
import sellerServicesSlice from './sellerServicesSlice';
import activityServicesSlice from './activityServicesSlice';
import sellerActivitySlice from './sellerActivitySlice';
import buyerSlice from './buyerSlice';
import employeeSlice from './employeeSlice';
import supplierSlice from './supplierSlice';
import supplierServicesSlice from './supplierServicesSlice';

export default configureStore({
  reducer: {
    i18n: i18nSlice,
    message: showMessage,
    user: userSlice,
    seller: sellerSlice,
    docType: documentTypeSlice,
    city: citySlice,
    state: stateSlice,
    serGroup: serviceGroupSlice,
    role: roleSlice,
    service: serviceSlice,
    question: questionSlice,
    answer: answerSlice,
    person: personSlice,
    job: jobSlice,
    diagnostic: diagnosticSlice,
    sellerServ: sellerServicesSlice,
    activityServ: activityServicesSlice,
    sellerActi: sellerActivitySlice,
    buyer: buyerSlice,
    employee: employeeSlice,
    supplier: supplierSlice,
    supplierServ: supplierServicesSlice,
  },
  devTools: true,
});
