import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';  

import {
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow
} from '@mui/material';
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import BorderColorRoundedIcon from '@mui/icons-material/BorderColorRounded';

import CustomLoading from '@components/CustomLoading';
import CustomTableHead from '@components/CustomTableHead';
import CustomTablePagination from '@components/CustomTablePagination';
import CustomButton from '@components/CustomButton';

import CustomDialog from './CustomDialog';
import { getAll, setRowsPerPage, setPage } from '../../../store/questionSlice';

const headCells = [
  {
    id: 'question_id',
    order: true,
    label: 'Id'
  },
  {
    id: 'question_service_group_id',
    order: true,
    label: 'Grupo'
  },
  {
    id: 'question_description',
    order: true,
    label: 'Descripción'
  },
  {
    id: 'question_order',
    order: true,
    label: 'Orden'
  },
  {
    id: 'question_percentage',
    order: true,
    label: 'Porcentaje'
  },
  {
    id: 'question_country_id',
    order: true,
    label: 'Pais'
  },
  {
    id: 'action',
    order: false,
    label: 'Acción'
  },
];

function Index() {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const rows = useSelector(({ question }) => question.dataList);
  const page = useSelector(({ question }) => question.page);
  const rowsPerPage = useSelector(({ question }) => question.rowsPerPage);
  const totalRows = useSelector(({ question }) => question.total);

  const [items, setItems] = useState([]);
  const [loading, setLoading] = useState(0);
  const [order, setOrder] = useState({
    direction: 'desc',
    id: 'question_id',
  });
  const [addDialog, setAddDialog] = useState(false);
  const [editDialog, setEditDialog] = useState(false);
  const [itemSelect, setItemSelect] = useState('');

  useEffect(() => {
    const fetch = async () => {
      setLoading(true);
      await dispatch(
        getAll(page, rowsPerPage, order.id, order.direction, {})
      );
      setLoading(false);
    };
    fetch();
  }, [dispatch, page, rowsPerPage, order.id, order.direction]);

  useEffect(() => {
    const fetch = async () => {
      if (rows && rows.length > 0) {
        setItems(rows);
      }
    };
    fetch();
  }, [rows]);

  /**
   * Handles the table sort request.
   * @param {string} property - Property to sort the table by.
   */
  const handleRequestSort = (event, property) => {
    const isAsc = order.id === property && order.direction === 'asc';
    setOrder({
      direction: isAsc ? 'desc' : 'asc',
      id: property,
    });
  };

  /**
   * Handles page change in table pagination.
   * @param {number} newPage - New selected page.
   */
  const handleChangePage = (event, newPage) => {
    dispatch(setPage(newPage));
  };
  /**
   * Handles the change in the number of rows per page in the table pagination.
   * @param {Object} event - React event object.
   */
  const handleChangeRowsPerPage = (event) => {
    dispatch(setRowsPerPage(parseInt(event.target.value, 10)));
    dispatch(setPage(0));
  };

  const toggleDialogCreate = () => {
    setAddDialog(!addDialog);
  };

  const toggleDialogEdit = () => {
    setEditDialog(!editDialog);
  };

  const handleRefresh = async () => {
    await dispatch(
      getAll(page, rowsPerPage, order.id, order.direction, {})
    );
  };

  return (
    <div>
      <div className="w-full flex justify-between items-center mt-4 bg-white p-4 rounded-lg h-16">
        <p className="text-xl font-semibold">{t('questions')}</p>
        <CustomButton
          label="Nuevo"
          typebutton={2}
          icon={<AddRoundedIcon fontSize="large" />}
          onClick={toggleDialogCreate}
        />
      </div>
      <div className="mt-7 bg-white px-2 py-6 rounded-lg flex flex-col">
        <div className="bg-red flex justify-end pb-4 px-4">
          <div className="flex">
            <CustomTablePagination
              page={page}
              count={totalRows}
              onPageChange={handleChangePage}
              rowsPerPage={rowsPerPage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </div>
        </div>
        {loading ? (
          <CustomLoading />
        ) : (
          <TableContainer component={Paper} sx={{ boxShadow: 'none' }}>
            <Table sx={{ minWidth: 700 }} aria-label="customized table">
              <CustomTableHead
                order={order.direction}
                orderBy={order.id}
                onRequestSort={handleRequestSort}
                headCells={headCells}
              />
              <TableBody>
                {items && items.length > 0 ? (
                  items.map((e) => {
                    return (
                      <TableRow
                        hover
                        key={e.question_id}
                      >
                        <TableCell sx={{ p: '15px 3px' }}>{e.question_id}</TableCell>
                        <TableCell sx={{ p: '15px 3px' }}>{e.fk_service_group?.group_name}</TableCell>
                        <TableCell sx={{ p: '15px 3px' }}>{e.question_description}</TableCell>
                        <TableCell sx={{ p: '15px 3px' }}>{e.question_order}</TableCell>
                        <TableCell sx={{ p: '15px 3px' }}>{e.question_percentage}</TableCell>
                        {/* <TableCell sx={{ p: '15px 3px' }}>{e.fk_country?.country_name}</TableCell> */}
                        <TableCell sx={{ p: '15px 3px' }}>
                          {e.questions_countries.map(i => 
                            `${i.fk_country?.country_name}${e.questions_countries[e.questions_countries.length - 1].qc_country_id === i.qc_country_id ? ' ' : ', '}`
                          )}
                        </TableCell>
                        <TableCell

                          align="center"
                          sx={{ p: '14px 3px' }}
                        >
                          <IconButton
                            onClick={() => {
                              setItemSelect(e);
                              setEditDialog(true)
                            }}
                          >
                            <BorderColorRoundedIcon
                              style={{ color: 'black' }}
                            />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    );
                  })
                ) : (
                  <TableRow>
                    <TableCell
                      align="center"
                      style={{ padding: 16, fontSize: 18 }}
                      colSpan={10}
                    >
                      No se han encontrado datos
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        )}
        <div className="flex justify-end mt-5 px-3">
          <CustomTablePagination
            page={page}
            count={totalRows}
            onPageChange={handleChangePage}
            rowsPerPage={rowsPerPage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </div>
      </div>
      {addDialog && (
        <CustomDialog
          handleClose={toggleDialogCreate}
          handleRefresh={handleRefresh}
          open={addDialog}
          type="create"
        />
      )}
      {editDialog && (
        <CustomDialog
          item={itemSelect}
          open={editDialog}
          handleClose={toggleDialogEdit}
          handleRefresh={handleRefresh}
          type="edit"
        />
      )}
    </div>
  );
}

export default Index;
