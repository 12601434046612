import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import md5 from 'md5';

import {
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  Slide,
  IconButton,
  InputAdornment,

  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  FormControl,
  FormHelperText,
  Link,
} from '@mui/material';
import { makeStyles } from '@mui/styles';

import { useForm, Controller, FormProvider } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { es } from 'date-fns/locale';
import { format, parseISO, parse, isValid } from 'date-fns';

import TodayRoundedIcon from '@mui/icons-material/TodayRounded';
import DeleteSweepRoundedIcon from '@mui/icons-material/DeleteSweepRounded';

import CustomButton from '@components/CustomButton';
import CustomTextField from '@components/CustomTextField';
import CustomAutocomplete from '@components/CustomAutocomplete';
import CustomUploadFile from '@components/CustomUploadFile';
import CustomTableHead from '@components/CustomTableHead';

import {
  create,
  update,
  getActivityType,
  createSellerActivityFile,
  getSellerActivityFile,
  setCleanFile,
  deleteSellerActivityFile
} from '../../../store/sellerActivitySlice';
import {
  getSellers,
} from '../../../store/sellerSlice';
import {
  getBuyers,
} from '../../../store/buyerSlice';
import { showMessage } from '../../../store/messageSlice';
import API from '../../../configs/urlsConfig';

/**
 * Transition is a function that creates a transition component using React's forwardRef function.
 *
 * @param {object} props - The properties of the Transition component.
 * @param {React.Ref} ref - Ref that is passed to the Slide component.
 * @returns {React.Component} - Transition component using Slide.
 */
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});


const headCells = [
  {
    id: 'saf_name',
    order: false,
    label: 'Nombre'
  },
  {
    id: 'saf_file',
    order: false,
    label: 'Archivo',
  },
  {
    id: 'action',
    order: false,
    label: 'Action',
  },
];

const useStyles = makeStyles((theme) => ({
  popper: {
    '& .MuiPopper-root': { zIndex: 9999 },
  },
  dialog: {
    // width: "100%",
    // backgroundColor: 'rgba(80, 80, 80, .5)',
    "& .MuiDialog-paperWidthSm": {
      maxWidth: 600,
      width: '100%',
    },
    // "& .MuiDialog-paperWidthMd": {
    //   maxWidth: 600,
    //   width: '100%',
    //   // backgroundColor: 'white',
    // },
  },
}));

const defaultValues = {
  seac_seller_id: null,
  seac_date: null,
  seac_description: '',
  seac_seller_activity_type_id: null,
  seac_buyer_id: null,
  seac_seller_user_id: '',
};

const schemaOne = yup.object().shape({
  seac_seller_id: yup.object().required('Vendedor es requerida'),
  seac_description: yup.string().required('Descripción es requerido'),
  seac_date: yup
    .string()
    .test('is-valid-date', 'Fecha inválida', (value) => {
      if (!value) return false;

      // Convierte el valor a un objeto Date
      const date = new Date(value);

      // Verifica si la fecha es válida
      if (!isValid(date)) return false;

      // Verifica que el año sea mayor o igual a 1900
      const year = date.getFullYear();
      return year >= 1900 && year <= 2050;;
    })
    .required('Fecha es requerida'),
  seac_seller_activity_type_id: yup.object().required('Tipo actividad es requerida'),
  // seac_buyer_id: yup.object().required('Vendedor es requerida')
});

const defaultValuesTwo = {
  saf_name: '',
  saf_file: null,
};

const schemaTwo = yup.object().shape({
  saf_name: yup.string().required('Nombre es requerido'),
  saf_file: yup.mixed().required('Imagen es requerido')
  // saf_file: yup.object().required('Documento es requerido').typeError('Documento es requerido'),
});


/**
 * Props for the CustomDialog component.
 * @property {boolean} open - Determines whether the dialog is open or closed.
 * @property {Function} handleClose - Callback function to handle the closing of the dialog.
 * @property {Function} handleRefresh - Callback function to handle the closing of the dialog.
 * @property {string} type - The type of operation ('create' or 'edit') the dialog is performing.
 * @property {Object} item - Object containing information about the partner being edited.
 */
function CustomDialog(props) {
  const { open, handleClose, type, item, handleRefresh } = props;
  const dispatch = useDispatch();
  const classes = useStyles();

  const sellers = useSelector(({ seller }) => seller.sellersList);
  const buyers = useSelector(({ buyer }) => buyer.buyersList);
  const activity_type = useSelector(({ sellerActi }) => sellerActi.activityTypeList);
  const files = useSelector(({ sellerActi }) => sellerActi.files);

  const [items, setItems] = useState([]);
  const [order, setOrder] = useState({
    direction: 'desc',
    id: 'saf_id',
  });
  const [loading, setLoading] = useState(false);
  const [loadingFile, setLoadingFile] = useState(false);
  const [activityId, setActivityId] = useState(null);
  const [changeDataFile, setChangeDataFile] = useState(false);
  const [loadingImages, setLoadingImages] = useState([]);
  const [sellerUserId, setSellerUserId] = useState(null);

  const methodsOne = useForm({
    mode: 'onChange',
    defaultValues,
    resolver: yupResolver(schemaOne),
  });
  const { reset: resetOne, control: controlOne, formState: formStateOne, handleSubmit: handleSubmitOne } = methodsOne;
  const { errors } = formStateOne;

  //-------------------------------------------
  const methodsTwo = useForm({
    mode: 'onChange',
    defaultValuesTwo,
    resolver: yupResolver(schemaTwo), // Resolver para la validación
  });

  const { reset: resetTwo, handleSubmit: handleSubmitTwo, control: controlTwo, setValue: setValueTwo, formState: formStateTwo } = methodsTwo;
  const { errors: errorsTwo } = formStateTwo;
  //-------------------------------------------
  const initDialog = useCallback(async () => {
    resetOne({
      ...item,
      seac_seller_id: {
        label: `${item.fk_seller?.seller_name}`,
        value: item.seac_seller_id,
      },
      seac_date: parseISO(item?.seac_date),
      seac_seller_activity_type_id: {
        label: `${item.fk_seller_activity_type?.sat_name}`,
        value: item.seac_seller_activity_type_id,
      },
      seac_buyer_id: item.seac_buyer_id === null || item.seac_buyer_id === '' ? null : {
        label: `${item.fk_buyer?.buyer_company}`,
        value: item.seac_buyer_id,
      },
    });
    setSellerUserId(item?.fk_seller?.persons?.person_user_id);
    setActivityId(item.seac_id);
    setChangeDataFile(true)
  });

  useEffect(() => {
    const fetch = async () => {
      if (type === 'edit') {
        initDialog();
      }
    };
    fetch();
  }, [type, resetOne, item]);

  useEffect(() => {
    async function init() {
      await dispatch(getSellers());
      await dispatch(getBuyers());
      await dispatch(getActivityType());
    }
    init();

    return async () => {
      await dispatch(setCleanFile())
    }
  }, []);

  useEffect(() => {
    const fetch = async () => {
      if (files) {
        setItems(files);
      }
    };
    fetch();
  }, [files]);

  useEffect(() => {
    async function fetch() {
      if (changeDataFile) {
        await dispatch(getSellerActivityFile(activityId));
        setChangeDataFile(false)
      }
    }
    fetch();
  }, [changeDataFile]);

  /**
  * Function to clean the information
  */
  const cleanData = () => {
    setActivityId(null);
    resetOne(defaultValues);
  };

  const cleanDataTwo = () => {
    resetTwo(defaultValuesTwo);
  };

  /**
 * Handles the table sort request.
 * @param {string} property - Property to sort the table by.
 */
  const handleRequestSort = (event, property) => {
    const isAsc = order.id === property && order.direction === 'asc';
    setOrder({
      direction: isAsc ? 'desc' : 'asc',
      id: property,
    });
  };
  // --------------------------------------------

  const optionType = activity_type
    ? activity_type.map((e) => ({
      value: e.sat_id,
      label: e.sat_name,
    }))
    : [];

  const optionSellers = sellers
    ? sellers.map((e) => ({
      value: e.seller_id,
      label: e.seller_name,
      person_user_id: e.persons?.person_user_id,
    }))
    : [];

  const optionBuyers = buyers
    ? buyers.map((e) => ({
      value: e.buyer_id,
      label: e.buyer_company,
    }))
    : [];
  // --------------------------------------------

  /**
   * Function to send the new partner information.
   * Checks if the 'PartnersName' field is empty and displays an error if so, otherwise closes the modal.
   * @returns {void} - Returns nothing.
   */
  const handleAction = async (data) => {
    setLoading(true);
    if (type === 'create' && activityId === null) {
      const response = await dispatch(create({
        ...data,
        seac_seller_id: data.seac_seller_id.value,
        seac_seller_activity_type_id: data.seac_seller_activity_type_id.value,
        seac_buyer_id: data.seac_buyer_id ? data.seac_buyer_id.value : null,
      }));
      if (response) {
        setActivityId(response.seac_id)
        await dispatch(showMessage({ message: 'Seller activities creada', variant: 'success' }));
        // cleanData();
        // handleClose();
        setLoading(false);
        handleRefresh();
      } else {
        setLoading(false);
      }

    } else {
      const response = await dispatch(update({
        ...data,
        seac_seller_id: data.seac_seller_id.value,
        seac_seller_activity_type_id: data.seac_seller_activity_type_id.value,
        seac_buyer_id: data.seac_buyer_id ? data.seac_buyer_id.value : null,
      },
        // item.seac_id
        activityId
      ));
      if (response) {
        await dispatch(showMessage({ message: 'Seller activities actualizada', variant: 'success' }));
        cleanData();
        setLoading(false);
        handleClose();
        handleRefresh();
      } else {
        setLoading(false);
      }
    }
  };

  const handleUploadChange = async (eventFile) => {
    const file = eventFile[0];
    if (!file) {
      return;
    }
    const formatType =
      file.type === 'application/pdf'
        ? 'pdf'
        : file.type === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
          ? 'docx'
          : file.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
            ? 'xlsx'
            : file.type === 'application/vnd.openxmlformats-officedocument.presentationml.presentation'
              ? 'xlspptxx'
              : file.type === 'image/png'
                ? 'png'
                : 'jpeg';
    const data = {
      file,
      hash_archivo: `${md5(Date.now())}.${formatType}`,
    };
    setValueTwo('saf_file', data);
    errorsTwo.saf_file = false;
    // ----------------------------------------------------------------------
    // const reader = new FileReader();
    // reader.readAsBinaryString(file);
    // reader.onload = async () => {
    //   const formatType =
    //     file.type === 'application/pdf'
    //       ? 'pdf'
    //       : file.type === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
    //         ? 'docx'
    //         : file.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
    //           ? 'xlsx'
    //           : file.type === 'application/vnd.openxmlformats-officedocument.presentationml.presentation'
    //             ? 'xlspptxx'
    //             : file.type === 'image/png'
    //               ? 'png'
    //               : 'jpeg';
    //   const data = {
    //     file,
    //     name_archivo: `${md5(Date.now())}.${formatType}`,
    //     url_base64: `data:${file.type};base64,${btoa(reader.result)}`,
    //   };
    //   setValueTwo('saf_file', data);
    //   errorsTwo.saf_file = false;
    // };
    // reader.onerror = function (error) {
    //   console.log('error on load image', error);
    // };
    // ----------------------------------------------------------------------
  };

  const handleUploadFile = async (data) => {
    setLoadingFile(true);
    const formData = new FormData();
    formData.append('file', data.saf_file.file);
    formData.append('hash_archivo', data.saf_file.hash_archivo);
    formData.append('saf_name', data.saf_name);
    formData.append('user_id', sellerUserId);
    formData.append('saf_seller_activities_id', activityId);

    const response = await dispatch(createSellerActivityFile(formData));
    if (response) {
      await dispatch(showMessage({ message: 'Archivo cargardo con exito', variant: 'success' }));
      cleanDataTwo();
      setChangeDataFile(true)
    }
    setLoadingFile(false);
  }

  const handleDeleteFile = async (row) => {
    setLoadingImages((prevLoading) => [...prevLoading, row.saf_id]);

    const response = await dispatch(deleteSellerActivityFile({ user_id: sellerUserId, saf_id: row.saf_id }))
    if (response) {
      await dispatch(showMessage({ message: 'Archivo eliminado con exito', variant: 'success' }));
      await dispatch(getSellerActivityFile(activityId));
    }
  }

  return (
    <Dialog open={open} TransitionComponent={Transition} className={classes.dialog}>
      <DialogTitle className="bg-gray-100 flex items-center justify-center rounded-t-lg">
        <p className="font-bold text-xl">
          {type === 'create' && activityId === null ? 'Crear' : 'Editar'} Seller activities
        </p>
      </DialogTitle>
      <DialogContent /*sx={{ width: '800px' }}*/ >
        <FormProvider {...methodsOne}>
          <div className="mt-4 flex flex-col">
            <div className="grid xl:grid-cols-2 grid-cols-2 gap-1">
              <Controller
                control={controlOne}
                name="seac_seller_id"
                render={({ field: { onChange, value, onBlur, ref } }) => (
                  <CustomAutocomplete
                    styles={{ mb: 2 }}
                    label="Vendedor"
                    id="seac_seller_id"
                    value={value}
                    options={optionSellers}
                    onChange={(event, newValue) => {
                      setSellerUserId(event.person_user_id)
                      onChange(event);
                    }}
                    renderOption={(props, option) => (
                      <li {...props} key={option.key}>
                        {option.label}
                      </li>
                    )}
                    error={!!errors.seac_seller_id}
                    helperText={errors?.seac_seller_id?.message}
                    required
                  />
                )}
              />
              <Controller
                control={controlOne}
                name="seac_seller_activity_type_id"
                render={({ field: { onChange, value, onBlur, ref } }) => (
                  <CustomAutocomplete
                    styles={{ mb: 2 }}
                    label="Tipo actividad"
                    id="seac_seller_activity_type_id"
                    value={value}
                    options={optionType}
                    onChange={(event, newValue) => {
                      onChange(event);
                    }}
                    renderOption={(props, option) => (
                      <li {...props} key={option.key}>
                        {option.label}
                      </li>
                    )}
                    error={!!errors.seac_seller_activity_type_id}
                    helperText={errors?.seac_seller_activity_type_id?.message}
                    required
                  />
                )}
              />
              <Controller
                control={controlOne}
                name="seac_buyer_id"
                render={({ field: { onChange, value, onBlur, ref } }) => (
                  <CustomAutocomplete
                    styles={{ mb: 2 }}
                    label="Comprador"
                    id="seac_buyer_id"
                    value={value}
                    options={optionBuyers}
                    onChange={(event, newValue) => {
                      onChange(event);
                    }}
                    renderOption={(props, option) => (
                      <li {...props} key={option.key}>
                        {option.label}
                      </li>
                    )}
                    error={!!errors.seac_buyer_id}
                    helperText={errors?.seac_buyer_id?.message}
                    required
                  />
                )}
              />
              <Controller
                control={controlOne}
                name="seac_date"
                render={({ field }) => (
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DatePicker
                      {...field}
                      clearable
                      value={field.value}
                      inputFormat="dd-MM-yyyy"
                      className={classes.popper}
                      disableFuture
                      // openTo="month"
                      // disablePast
                      label="Fecha actividad"
                      // onChange={(event, newValue) => {
                      //   field.onChange(event);
                      // }}
                      renderInput={(_props) => (
                        <CustomTextField
                          {..._props}
                          defaultValue=""
                          styles={{ mb: 2, width: '100%' }}
                          id="seac_date"
                          error={!!errors.seac_date}
                          helperText={errors?.seac_date?.message}
                          required
                        // {...field}
                        />
                      )}
                      components={{
                        OpenPickerIcon: () => (
                          <TodayRoundedIcon className="text-48 mr-8" size={14} />
                        ),
                      }}
                    />
                  </LocalizationProvider>
                )}
              />
              <div className='xl:col-span-2 col-span-1'>
                <Controller
                  control={controlOne}
                  name="seac_description"
                  render={({ field }) => (
                    <CustomTextField
                      {...field}
                      styles={{ mt: 1, mb: 2, width: '100%' }}
                      id="seac_description"
                      label="Descripción"
                      multiline
                      rows={4}
                      maxRows={4}
                      error={!!errors.seac_description}
                      helperText={errors?.seac_description?.message}
                      required
                    />
                  )}
                />
              </div>
            </div>
          </div>
          <div className="flex justify-end my-4 items-center">
            {loading ? (
              <CircularProgress
                style={{ color: '#4575D5', marginRight: 4 }}
                size={24}
              />
            ) : (
              <CustomButton
                label="Guardar"
                typebutton={2}
                onClick={handleSubmitOne(handleAction)}
              />
            )}
          </div>
        </FormProvider>

        {/* ---------------------- */}
        {(activityId !== null) && (
          <FormProvider {...methodsTwo}>
            <div className=''>
              <p className="text-16 font-bold my-2">Cargar de archivos</p>
              <div className="mt-4">
                <div className="grid xl:grid-cols-1 grid-cols-2 gap-1">
                  <div className='xl:col-span-1 col-span-2'>
                    <div className='mb-2'>
                      <Typography color="#262940" className="text-16">
                        Adjuntar documento de soporte{' '}
                        <span style={{ color: '#B7BECD' }}>(jpg, jpeg, png):</span>
                      </Typography>
                    </div>
                    <Controller
                      name="saf_file"
                      control={controlTwo}
                      render={({ field }) => (
                        <CustomUploadFile
                          {...field}
                          id="saf_file"
                          handleUploadChange={(e) => handleUploadChange(e)}
                          accept={{
                            'image/*': ['.jpg', '.jpeg', '.png'],
                          }}
                          required={!!errorsTwo.saf_file}
                        />
                      )}
                    />
                    <div className=''>
                      <FormControl error={!!errorsTwo.saf_file} className="w-full">
                        <FormHelperText style={{ marginLeft: 0 }}>{errorsTwo?.saf_file?.message}</FormHelperText>
                      </FormControl>
                    </div>
                  </div>
                  <div className="flex justify-between w-full">
                    <div className='xl:col-span-1 col-span-2'>
                      <Controller
                        name="saf_name"
                        control={controlTwo}
                        render={({ field }) => (
                          <CustomTextField
                            {...field}
                            styles={{ mt: 1, mb: 2, width: '100%' }}
                            id="saf_name"
                            label="Nombre"
                            error={!!errorsTwo.saf_name}
                            helperText={errorsTwo?.saf_name?.message}
                            required
                          />
                        )}
                      />
                    </div>
                    <div className='xl:col-span-1 col-span-2 mt-4'>
                      {loadingFile ? (
                        <CircularProgress
                          style={{ color: '#4575D5', marginRight: 4 }}
                          size={24}
                        />
                      ) : (
                        <CustomButton
                          label="Agregar"
                          typebutton={2}
                          onClick={handleSubmitTwo(handleUploadFile)}
                        />
                      )}
                    </div>
                  </div>
                </div>
                <TableContainer>
                  <Table size="small" className="simple mt-4">
                    <CustomTableHead
                      order={order.direction}
                      orderBy={order.id}
                      onRequestSort={handleRequestSort}
                      headCells={headCells}
                    />
                    <TableBody>
                      {items.length > 0 ? items.map((row) => (
                        <TableRow
                          key={row.saf_id}
                          sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >
                          <TableCell sx={{ p: '15px 10px 15px 3px' }} style={{ fontSize: 12 }}>{row.saf_name}</TableCell>
                          <TableCell
                            align="left"
                            sx={{ p: '14px 3px' }}
                          >
                            <Link
                              target="_blank"
                              href={`${API.url_ftp}/${sellerUserId}/actividades/${row.saf_file}`}
                              color="#213462"
                              className="text-12 font-bold cursor-pointer"
                            >
                              {/* row.saf_file */}
                              Ver
                            </Link>
                          </TableCell>
                          <TableCell
                            align="left"
                            sx={{ p: '14px 3px' }}
                          >
                            {loadingImages.includes(row.saf_id) ? (
                              <CircularProgress
                                style={{ color: '#4575D5', marginRight: 4 }}
                                size={24}
                              />
                            ) :
                              <IconButton
                                onClick={async () => {
                                  handleDeleteFile(row)
                                }}
                              >
                                <DeleteSweepRoundedIcon
                                  style={{ color: '#213462' }}
                                />
                              </IconButton>
                            }
                          </TableCell>
                        </TableRow>
                      )) :
                        <TableRow>
                          <TableCell
                            align="center"
                            style={{ padding: 16, fontSize: 12 }}
                            colSpan={5}
                          >
                            No se han encontrado archivos
                          </TableCell>
                        </TableRow>
                      }
                    </TableBody>
                  </Table>
                </TableContainer>
              </div>
            </div>
          </FormProvider>
        )}
        {/* ---------------------- */}
        <div className="flex justify-end my-4 items-center">
          <p
            className="mx-4 underline text-base text-gray-600 cursor-pointer"
            onClick={() => {
              handleClose();
              setLoading(false);
              cleanData();
              cleanDataTwo()
            }}
          >
            Cerrar
          </p>
        </div>
      </DialogContent>
    </Dialog>
  );
}

export default CustomDialog;
