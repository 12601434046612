import Typography from '@mui/material/Typography';
import { Link as RouterLink } from 'react-router-dom';

/**
 * The Error404Page component renders a custom 404 error page.
 */
function Index() {

	return (
		<div className="flex flex-1 flex-col items-center justify-center p-16">
			<div className="w-full max-w-3xl text-center">
				<div
					initial={{ opacity: 0, y: 40 }}
					animate={{ opacity: 1, y: 0, transition: { delay: 0.2 } }}
				>
					<Typography
						variant="h5"
						color="text.secondary"
						className="mt-8 text-center text-lg font-medium tracking-tight md:text-xl"
					>
						The page you requested could not be found.
					</Typography>
				</div>
				<RouterLink
					className="block font-normal mt-6"
					to={'/'}
				>
					Regresar al Inicio
				</RouterLink>
			</div>
		</div>
	);
}

export default Index;
