import currency from 'currency.js';

export function amountFormat(num) {
  if (num.endsWith(',00')) {
    return num.slice(0, -3);
  } else if (num.endsWith('.00')) {
    return num;
  } else if (num.includes(',')) {
    const parts = num.split(',');
    if (parts.length === 2 && parts[1].length === 1) {
      return `${parts[0]}.${parts[1]}0`;
    } else {
      return num.replace(',', '.');
    }
  } else {
    if (num.endsWith('.')) {
      return num + '00';
    } else {
      const decimalIndex = num.indexOf('.');
      if (decimalIndex !== -1 && num.slice(decimalIndex + 1).length === 1) {
        return num + '0';
      } else if (!num.includes('.')) {
        return num + '.00';
      } else {
        return num;
      }
    }
  }
};

export function formatCurrency(value) {
  // Normaliza el valor, reemplazando comas por puntos
  const normalizedValue = value.replace(/,/g, '').replace(/,/g, '');

  // Usa currency.js para formatear el valor
  return currency(normalizedValue, { symbol: "$", precision: 2 }).format();
};