import * as React from 'react';
import PropTypes from 'prop-types';
import { Alert as MuiAlert, Snackbar, Stack } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { hideMessage } from '../../store/messageSlice';

const propTypes = {
  open: PropTypes.bool,
  message: PropTypes.string,
  severity: PropTypes.string
};

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

/**
 * @param {Object} props - Properties of the component.
 * @param {boolean} open - Determines if the Snackbar is open or closed.
 * @param {string} message - Message to be displayed on the Snackbar.
 * @param {string} severity - Severity of the notification ('success' or 'error').
 */
function CustomSnackbar(props) {

  const dispatch = useDispatch();
  const state = useSelector(({ message }) => message.state);
  const options = useSelector(({ message }) => message.options);

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    dispatch(hideMessage(state))
  };

  return (
    <Stack
      {...options}
      open={state}
      onClose={handleClose}
      spacing={2} sx={{ width: '100%' }}>
      <Snackbar
        open={state}
        onClose={handleClose}
        autoHideDuration={options.autoHideDuration}
        anchorOrigin={options.anchorOrigin}
        key={options.anchorOrigin.vertical + options.anchorOrigin.horizontal}
      >
        <Alert onClose={handleClose} severity={options.variant} sx={{ width: '100%' }}>
          {options.message}
        </Alert>
      </Snackbar>

    </Stack>
  );
}

CustomSnackbar.propTypes = propTypes;

export default CustomSnackbar;
