import axios from 'axios';
import { createSlice } from '@reduxjs/toolkit';
import API from '../configs/urlsConfig';
import { showMessage } from './messageSlice';

export const getAll = (page, per_page, orderBy, order, params) => async (dispatch) => {
  try {
    const queryString = `?page=${page}&rows=${per_page}&order_field=${orderBy}&order_type=${order}&filters=${JSON.stringify(params)}`;
    const URL = `${API.baseUrl}/${API.endpoints.persons}`;
    const result = await axios.get(URL + queryString);
    await dispatch(setDataList(result.data));
    return result;
  } catch (error) {
    throw new Error(`*** REDUX -> getAll *** ${error}`);
  }
};

export const create = (data) => async (dispatch) => {
  try {
    const URL = `${API.baseUrl}/${API.endpoints.persons}`;
    const result = await axios.post(URL, data);
    if (result.status === 200) {
      return true;
    } else {
      await dispatch(showMessage({ message: result.data?.message, variant: result.data?.status }));
      return false;
    }
  } catch (error) {
    throw new Error(`*** REDUX -> create *** ${error}`);
  }
};

export const update = (data, id) => async (dispatch) => {
  try {
    const URL = `${API.baseUrl}/${API.endpoints.persons}/${id}`;
    const result = await axios.put(URL, data);
    if (result.status === 200) {
      return true;
    } else {
      await dispatch(showMessage({ message: result.data?.message, variant: result.data?.status }));
      return false;
    }
  } catch (error) {
    throw new Error(`*** REDUX -> update *** ${error}`);
  }
};

// *** ROL SELLER ***
export const getProfileById = (id) => async (dispatch) => {
  try {
    const URL = `${API.baseUrl}/${API.endpoints.persons}/profile/${id}`;
    const result = await axios.get(URL);
    await dispatch(setDataProfile(result.data));
    return true;
  } catch (error) {
    throw new Error(`*** REDUX -> getProfileById *** ${error}`);
  }
};

// *** ROL SELLER ***
export const updateProfile = (data) => async (dispatch) => {
  try {
    const URL = `${API.baseUrl}/${API.endpoints.persons}/profile`;
    const result = await axios.post(URL, data);
    if (result?.status === 201) {
      await dispatch(showMessage({ message: `${result.data?.message}`, variant: `${result.data?.status}` }));
      return false;
    }
    return result.data;
  } catch (error) {
    // throw new Error(`*** REDUX -> updateProfile *** ${error}`);
    await dispatch(showMessage({ message: `${error.message}`, variant: 'error' }));
    return false
  }
};

const personSlice = createSlice({
  name: 'person',
  initialState: {
    enabledIntegration: false,
    dataList: [],
    page: 0,
    rowsPerPage: 10,
    total: 0,
    dataProfile: {}
  },
  reducers: {
    setDataList: (state, action) => {
      state.dataList = action.payload.rows;
      state.total = action.payload.count;
    },
    setRowsPerPage: (state, action) => {
      state.rowsPerPage = action.payload;
    },
    setPage: (state, action) => {
      state.page = action.payload;
    },
    setDataProfile: (state, action) => {
      state.dataProfile = action.payload;
    },
  }
});

export const { setDataList, setRowsPerPage, setPage, setTotalRows, setDataProfile } =
  personSlice.actions;

export default personSlice.reducer;
