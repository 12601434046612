import axios from 'axios';
import { createSlice } from '@reduxjs/toolkit';
import API from '../configs/urlsConfig';
import { showMessage } from './messageSlice';

// *** ROL SELLER ***
export const getQuestionByCountry = (id) => async (dispatch) => {
  try {
    const URL = `${API.baseUrl}/${API.endpoints.diagnostics}/${id}`;
    const result = await axios.get(URL);
    await dispatch(setDataList(result.data));
    return result;
  } catch (error) {
    throw new Error(`*** REDUX -> getQuestionByCountry *** ${error}`);
  }
};

// *** ROL SELLER ***
export const getVerifyAnswer = (user) => async (dispatch) => {
  try {
    const URL = `${API.baseUrl}/${API.endpoints.diagnostics}/verify?user_id=${user.user_id}`;
    const result = await axios.get(URL);
    await dispatch(setDataAnswer(result.data));
    return result;
  } catch (error) {
    throw new Error(`*** REDUX -> getVerifyAnswer *** ${error}`);
  }
};

// *** ROL SELLER ***
export const getScore = (diagnostic_id) => async (dispatch) => {
  try {
    const URL = `${API.baseUrl}/${API.endpoints.diagnostics}/score?diagnostic_id=${diagnostic_id}`;
    const result = await axios.get(URL);
    await dispatch(setDataScore(result.data));
    return result;
  } catch (error) {
    throw new Error(`*** REDUX -> getScore *** ${error}`);
  }
};

// *** ROL SELLER ***
export const saveAnswer = (data) => async (dispatch) => {
  try {
    const URL = `${API.baseUrl}/${API.endpoints.diagnostics}/answer`;
    await axios.post(URL, data);
    return true;
  } catch (error) {
    throw new Error(`*** REDUX -> saveAnswer *** ${error}`);
  }
};

// *** ROL SELLER ***
export const saveScore = (data) => async (dispatch) => {
  try {
    const URL = `${API.baseUrl}/${API.endpoints.diagnostics}/score`;
    await axios.post(URL, data);
    return true;
  } catch (error) {
    throw new Error(`*** REDUX -> saveScore *** ${error}`);
  }
};

// *** ROL SELLER ***
export const update = (data, id) => async (dispatch) => {
  try {
    const URL = `${API.baseUrl}/${API.endpoints.questions}/${id}`;
    await axios.put(URL, data);
    return true;
  } catch (error) {
    throw new Error(`*** REDUX -> update *** ${error}`);
  }
};

// *** ROL SELLER ***
export const savePDF = (data) => async (dispatch) => {
  try {
    const URL = `${API.baseUrl}/${API.endpoints.diagnostics}/generate_pdf`;
    const result = await axios.post(URL, data);
    return result.data;
  } catch (error) {
    throw new Error(`*** REDUX -> savePDF *** ${error}`);
  }
};

const diagnosticSlice = createSlice({
  name: 'diagnostic',
  initialState: {
    enabledIntegration: false,
    dataList: [],
    questionsList: [],
    dataAnswer: {},
    dataScore: {},
  },
  reducers: {
    setCleanDiagnostic: (state) => {
      state.dataList = [];
      state.dataAnswer = {};
    },
    setDataList: (state, action) => {
      state.dataList = action.payload;
    },
    setDataAnswer: (state, action) => {
      state.dataAnswer = action.payload;
    },
    setDataScore: (state, action) => {
      state.dataScore = action.payload;
    },
  }
});

export const { setCleanDiagnostic, setDataList, setDataAnswer, setDataScore } =
  diagnosticSlice.actions;

export default diagnosticSlice.reducer;
